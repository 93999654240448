<template>
  <v-container fluid fill-height>
    <v-row align="start" class="mx-3" no-gutters>
      <v-col>
        <presta-editor :comment="note" :title="$t('header.providers_note')">
        </presta-editor>
      </v-col>
    </v-row>
    <v-row align="start" justify="center" class="fill-height" no-gutters>
      <onglet></onglet>
    </v-row>
  </v-container>
</template>
<script>
import Onglet from "../prestation/Onglet";
import { mapGetters } from "vuex";
import PrestaEditor from "../editor/PrestaEditor";

export default {
  name: "OngletSynthesePrestation",
  props: {
    note: {
      type: String,
      default: null
    }
  },
  components: { Onglet, PrestaEditor },
  computed: {
    ...mapGetters(["getEntity", "getActiveEntity"]),
    selectedEntity() {
      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    isLocation() {
      let module = this.getEntity.find(ent => ent.id === this.$route.params.id);
      if (module.state) {
        if (!module.state.includes("PluginGenericobject")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
};
</script>
