var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alert),expression:"alert"}],attrs:{"type":_vm.alertType,"cols":"5"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]),_c('v-card-title',[(!_vm.isMobile)?_c('v-btn',{staticClass:"mb-mt-my-16",attrs:{"mb":"6","color":"primary","disabled":_vm.selectedDocs.length === 0,"absolute":"","left":""},on:{"click":_vm.deleteSelectedFiles}},[_vm._v(" Supprimer les docs ")]):_vm._e()],1),(_vm.isMobile)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticStyle:{"max-height":"640px","overflow":"auto"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.loadingDoc)?_vm._l((2),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-skeleton-loader',{ref:"skeleton",refInFor:true,staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line"}})],1)],1)}):_vm._l((_vm.docList),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.documentIcon(item.mime))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":"Ouvrir le document dans une nouvelle fenêtre"},domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){return _vm.openFile(item)}}}),(item.documentcategories_id !== 0)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.documentcategories_id)}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                  _vm.moment(item.date_creation, 'YYYY-MM-Do HH:mm ss').format(
                    ' Do MMM YYYY, HH:mm'
                  )
                )}})],1),_c('v-list-item-icon',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary","title":_vm.$t('assetDetails.text3')},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download-outline")])],1)],1)],1)})],2)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticStyle:{"max-height":"640px","overflow":"auto"}},[_c('v-list-item-group',{attrs:{"color":"primary","multiple":"","active-class":""},model:{value:(_vm.selectedDocs),callback:function ($$v) {_vm.selectedDocs=$$v},expression:"selectedDocs"}},[(_vm.loadingDoc)?_vm._l((2),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-skeleton-loader',{ref:"skeleton",refInFor:true,staticClass:"mx-auto",attrs:{"type":"list-item-avatar-two-line"}})],1)],1)}):_vm._l((_vm.docList),function(item){return _c('v-list-item',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.documentIcon(item.mime))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),(item.documentcategories_id !== 0)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.documentcategories_id)}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    _vm.moment(item.date_creation, 'YYYY-MM-Do HH:mm ss').format(
                      ' Do MMM YYYY, HH:mm'
                    )
                  )}})],1),_c('v-list-item-icon',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary","title":_vm.$t('assetDetails.text2')},on:{"click":function($event){return _vm.openFile(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-eye")])],1)],1),_c('v-list-item-icon',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary","title":_vm.$t('assetDetails.text3')},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download-outline")])],1)],1)]}}],null,true)})})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }