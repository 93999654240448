<template>
  <v-container fluid fill-height class="mx-0 px-md-3 px-0">
    <slot v-bind:exposedData="exposedData"></slot>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import USER_API from "../../api/user";
import { getStatus, loadStates, remove } from "../../utils";
import { EventBus } from "../../event-bus";
import moment from "../../plugins/moment";

export default {
  name: "TicketAccess",
  props: {
    filtered: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tickets: [],
      ticketsFields: [],
      rooms: [],
      loaded: false,
      loading: false,
      categories: ["Acces"]
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getEntity",
      "getActiveEntity",
      "getTickets",
      "getRooms",
      "getTicketLoadStatus",
      "getRoomLoadStatus",
      "getAllITILSolutions",
      "getAllITILFollowups",
      "getTicketsTasks"
    ]),
    display() {
      return this.getActiveEntity.id === this.$route.params.id;
    },
    filteredTickets() {
      return this.tickets.filter(
        ticket =>
          this.categories.includes(ticket.itilcategories_id) ||
          (!ticket.id && ticket.entities_id === this.$route.params.id)
      );
    },
    exposedData() {
      return {
        tickets: this.filtered ? this.filteredTickets : this.tickets,
        loaded: this.loaded,
        loading: this.loading
      };
    },
    equipments() {
      return this.getEquipments.filter(
        eqp => eqp.entities_id === +this.$route.params.id
      );
    },
    selectedEntity() {
      if (this.$route.meta.directLink) {
        return this.getActiveEntity;
      }

      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    }
  },
  methods: {
    ...mapActions(["loadRoom", "loadTickets"]),
    async loadData() {
      this.loading = true;
      if (
        this.getTicketLoadStatus === loadStates.INITIAL_STATE ||
        !this.$route.meta.directLink
      ) {
        let { data: tickets } = await USER_API.getTickets();
        this.rooms = (await USER_API.getRoom()).data;
        this.loadRoom(this.rooms);
        await this.getRoomsData(tickets);
      } else {
        this.tickets = this.getTickets;
        this.rooms = this.getRooms;
        this.loaded = true;
        this.loading = false;
      }
      //this.loading = false;
    },

    async getRoomsData(ticketsData) {
      let total = this.rooms.length;
      let tickets = [];
      for (let i = 0; i < total; i++) {
        let found = false;
        const roo = this.rooms[i];
        //console.log(roo.name);
        const { data } = await USER_API.getRoomTicketItems(roo.id);
        for (let j = 0; j < data.length; j++) {
          let ticket = data[j];
          let ticketIndex = ticketsData.findIndex(
            tkt => tkt.id === ticket.tickets_id
          );
          if (ticketIndex > -1) {
            found = true;
            let currentTicket = ticketsData[ticketIndex];
            currentTicket.room = roo.name;
            const { date, takeintoaccount_delay_stat } = ticketsData[
              ticketIndex
            ];
            currentTicket.attributdate =
              takeintoaccount_delay_stat > 0
                ? moment(date)
                    .add(takeintoaccount_delay_stat, "s")
                    .format("DD-MM-YYYY HH:mm")
                : "";

            currentTicket.statusName = getStatus(
              ticketsData[ticketIndex].status
            );
            this.getLastComment(ticket.tickets_id).then(comment => {
              currentTicket.last_comment = comment;

              tickets.push(currentTicket);
              ticketsData = ticketsData.filter(
                el => el.id !== currentTicket.id
              );
            });
          }
        }
        if (!found) {
          let rooticket = {};
          rooticket.room = roo.name;
          tickets.push(rooticket);
        }
      }
      const remainingTickets = remove(ticketsData, t => {
        return !t.hasOwnProperty("room");
      });
      remainingTickets.forEach(ticket => {
        ticket.room = "Sans room";
        ticket.no_room = true;
        const { date, takeintoaccount_delay_stat } = ticket;
        ticket.attributdate =
          takeintoaccount_delay_stat > 0
            ? moment(date)
                .add(takeintoaccount_delay_stat, "s")
                .format("DD-MM-YYYY HH:mm")
            : "";

        ticket.statusName = getStatus(ticket.status);
        this.getLastComment(ticket.id).then(comment => {
          ticket.last_comment = comment;
          tickets.push(ticket);
        });
      });
      this.tickets = tickets;
      this.loaded = true;
      this.loading = false;
      this.loadTickets(this.tickets);
    },
    updateTicket(id) {
      const oldTicket = this.tickets.find(ticket => ticket.id === id);
      const oldTicketIndex = this.tickets.findIndex(ticket => ticket.id === id);
      USER_API.getTicket(id).then(({ data }) => {
        data.room = oldTicket.room;
        this.$set(this.tickets, oldTicketIndex, data);
      });
    },
    updateTicketLastComment(content, ticket_id) {
      const oldTicket = this.tickets.find(ticket => ticket.id === ticket_id);
      const oldTicketIndex = this.tickets.findIndex(
        ticket => ticket.id === ticket_id
      );
      oldTicket.last_comment = content;
      this.$set(this.tickets, oldTicketIndex, oldTicket);
    },
    async getLastComment(ticketID) {
      const followUps = this.getAllITILFollowups.filter(
        fl => fl.items_id === ticketID
      );
      const tasks = this.getTicketsTasks.filter(fl => fl.items_id === ticketID);
      const solutions = this.getAllITILSolutions.filter(
        fl => fl.items_id === ticketID
      );

      let alldata = followUps.concat(tasks).concat(solutions);

      let sortedData = alldata.sort((a, b) => {
        if (moment(a.date_creation).isAfter(moment(b.date_creation))) {
          return -1;
        } else if (moment(b.date_creation).isAfter(moment(a.date_creation))) {
          return 1;
        } else {
          return 0;
        }
      });

      const lastComment = sortedData[0] ? sortedData[0].content : null;

      return lastComment;
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    EventBus.$on("ticket-resolu", this.updateTicket);
    EventBus.$on("new-content", this.updateTicketLastComment);
  },
  destroyed() {
    // Destroy event listening
    EventBus.$off("ticket-resolu");
    EventBus.$off("new-content");
  },
  watch: {
    selectedEntity() {
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
