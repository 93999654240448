<template>
  <v-container fluid fill-height>
    <tab-onglet v-if="Object.keys(getStates).length > 1"></tab-onglet>
    <v-row align="start" justify="center" class="fill-height" no-gutters v-else>
      <component :is="currentComponent" v-if="currentComponent"></component>
    </v-row>
  </v-container>
</template>
<script>
import TabOnglet from "./TabOnglet";
import { mapGetters } from "vuex";

export default {
  name: "OngletSyntheseCarto",
  components: { TabOnglet },
  computed: {
    ...mapGetters(["getEntity", "getActiveEntity"]),
    selectedEntity() {
      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    currentComponent() {
      let module = this.selectedEntity;
      if (module && module.state) {
        if (!module.state.includes("PluginGenericobject")) {
          return "location";
        } else {
          return this.getFirstElement;
        }
      } else {
        return "location";
      }
    },
    getStates() {
      let states = this.getActiveEntity.state;
      return states ? JSON.parse(states) : { location: "Emplacement" };
    },
    getFirstElement() {
      const keys = Object.keys(this.getStates);
      return keys[0];
    }
  }
};
</script>
