<template>
  <v-row no-gutters class="pt-6">
    <v-col cols="3" class="mt-n5 mb-n5">
      <v-select
        :label="$t('tableStatus.filter_name')"
        :items="docNames"
        clearable
        solo
        v-model="names"
        multiple
      ></v-select>
    </v-col>
    <v-col cols="3" offset="1" class="mt-n5 mb-n5">
      <v-select
        :label="$t('tableStatus.by_category')"
        :items="docCategories"
        clearable
        solo
        chips
        v-model="categories"
        multiple
      ></v-select>
    </v-col>
    <v-col cols="4" offset="1" class="mt-n5 mb-n5">
      <v-daterange
        v-model="ranges"
        display-format="dd-MM-yyyy"
        no-title
        :menu-props="menuProps"
        :presets="presets"
        :start-label="$t('labels.start_date')"
        :end-label="$t('labels.end_date')"
        :separator-label="$t('labels.to')"
        :locale="$root.$i18n.locale"
      ></v-daterange>
    </v-col>
  </v-row>
</template>
<script>
import { DocFilterMixin } from "./filter.mixins";

export default {
  mixins: [DocFilterMixin]
};
</script>
