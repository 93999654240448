<template>
  <v-container fluid fill-height>
    <v-row align="start" justify="center" class="fill-height" no-gutters>
      <occupants> </occupants>
      <charges></charges>
      <travaux></travaux>
    </v-row>

    <v-row v-if="isMobile">
      <v-row>
        <v-col cols="8" offset="1" class="headline display-1 mt-4">
          Compte locataire
        </v-col>
        <v-col cols="1" class="mt-2">
          <v-dialog v-model="docFilterDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                <v-icon dark>mdi-calendar-search</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Sélection de date</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-daterange
                        v-model="dateRange"
                        display-format="dd-MM-yyyy"
                        no-title
                        :menu-props="menuProps"
                        :presets="presets"
                      ></v-daterange>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="docFilterDialog = false"
                  >OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="mt-n6">
        <v-col cols="12">
          <v-list style="max-height: 640px; overflow: auto">
            <v-list-item-group color="primary">
              <template v-if="loadingDoc">
                <v-list-item v-for="(item, i) in 2" :key="i">
                  <v-list-item-content>
                    <v-skeleton-loader
                      ref="skeleton"
                      type="list-item-avatar-two-line"
                      class="mx-auto"
                    ></v-skeleton-loader>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item v-for="item in docList" :key="item.id">
                  <v-list-item-avatar>
                    <v-icon large color="primary">
                      {{ documentIcon(item.mime) }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.name"
                      @click="openFile(item)"
                      title="Ouvrir le document dans une nouvelle fenêtre"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-if="item.documentcategories_id !== 0"
                      v-text="item.documentcategories_id"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-text="
                        moment(
                          item.date_creation,
                          'YYYY-MM-Do HH:mm ss'
                        ).format(' Do MMM YYYY, HH:mm')
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="downloadFile(item)"
                      title="Télécharger le document"
                    >
                      <v-icon dark>mdi-cloud-download-outline</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-row>

    <v-row v-else>
      <v-col flat class="mx-10">
        <v-card-title class="headline display-1">
          <v-col cols="5">
            {{ $t("header.tenant_account") }}
          </v-col>
          <v-col cols="7">
            <v-daterange
              v-model="dateRange"
              display-format="dd-MM-yyyy"
              no-title
              :menu-props="menuProps"
              :presets="presets"
              :start-label="$t('labels.start_date')"
              :end-label="$t('labels.end_date')"
              :separator-label="$t('labels.to')"
              :locale="$root.$i18n.locale"
            >
            </v-daterange>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters class="pa-2">
            <v-col cols="12" class="mt-n12">
              <v-list style="max-height: 640px; overflow: auto">
                <v-list-item-group color="primary">
                  <template v-if="loadingDoc">
                    <v-list-item v-for="(item, i) in 2" :key="i">
                      <v-list-item-content>
                        <v-skeleton-loader
                          ref="skeleton"
                          type="list-item-avatar-two-line"
                          class="mx-auto"
                        ></v-skeleton-loader>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-else>
                    <v-list-item
                      v-for="(item, index) in docList"
                      :key="item.id"
                    >
                      <v-list-item-avatar>
                        <v-icon large color="primary">
                          {{ documentIcon(item.mime) }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                          @click="openFile(item)"
                          title="Ouvrir le document dans une nouvelle fenêtre"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="item.documentcategories_id !== 0"
                          v-text="item.documentcategories_id"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-text="
                            moment(
                              item.date_creation,
                              'YYYY-MM-Do HH:mm ss'
                            ).format(' Do MMM YYYY, HH:mm')
                          "
                        ></v-list-item-subtitle>
                        <v-card class="preview__pdf" v-if="index === 0">
                          <v-row v-if="src">
                            <v-col cols="12" v-for="i in pageCount" :key="i">
                              <pdf
                                :src="src"
                                :page="i"
                                @page-count="pageCount = $event"
                                style="display: block; width: 100%"
                              ></pdf>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="primary"
                          @click="downloadFile(item)"
                          title="Télécharger le document"
                        >
                          <v-icon dark>mdi-cloud-download-outline</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Occupants from "./Occupants";
import Charges from "./Charges";
import Travaux from "./Travaux";
import { mapGetters } from "vuex";
import moment from "../../plugins/moment";
import VDaterange from "../VDaterange";
import USER_API from "../../api/user";
import { base64ToString, permissions } from "../../utils";
import { blobTob64 } from "../../utils/upload";
import pdf from "vue-pdf";

export default {
  name: "OngletEconomie",
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  components: { VDaterange, Occupants, Charges, Travaux, pdf },
  data() {
    return {
      dialog: false,
      documentsList: [],
      loadingDoc: false,
      activeEntity: null,
      docFilterDialog: false,
      selectedNames: [],
      note: null,
      comment: "",
      selectedCategories: [this.$t("index.account")],
      search: "",
      dateRange: {},
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: [
        {
          label: this.$t("index.today"),
          range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        {
          label: this.$t("index.yesterday"),
          range: [
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: this.$t("index.last_days"),
          range: [
            moment()
              .subtract(30, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        }
      ],
      currentPage: 0,
      pageCount: 1,
      src: null
    };
  },
  computed: {
    ...mapGetters(["getUserProfiles", "getUserId"]),

    docNames() {
      return this.authorizedDocs.map(doc => doc.name);
    },
    docCategories() {
      let categories = this.authorizedDocs.map(doc => {
        if (doc.documentcategories_id === 0) {
          return "Sans Catégorie";
        }
        return doc.documentcategories_id;
      });
      return [...new Set(categories)];
    },
    authorizedDocs() {
      return this.documentsList.filter(doc => {
        const comment = "" + doc.comment;
        const per = comment.substring(0, 3);
        return this.checkDocPermission(per);
      });
    },
    filteredDocList() {
      return this.authorizedDocs.filter(doc => {
        if (doc.documentcategories_id === 0) {
          doc.documentcategories_id = "Sans Catégorie";
        }
        const dateCreation = moment(
          doc.date_creation,
          "YYYY-MM-Do HH:mm ss"
        ).format("YYYY-MM-DD");

        return (
          (this.selectedNames.length === 0 ||
            this.selectedNames.includes(doc.name)) &&
          (this.selectedCategories.length === 0 ||
            this.selectedCategories.includes(doc.documentcategories_id)) &&
          this.filterByDate(dateCreation)
        );
      });
    },
    docList() {
      if (!this.search) return this.filteredDocList;
      return this.filteredDocList.filter(doc => {
        if (doc.documentcategories_id === 0)
          doc.documentcategories_id = "Sans Catégorie";

        return (
          doc.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          doc.documentcategories_id
            .toLowerCase()
            .search(this.search.toLowerCase()) > -1
        );
      });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    moment,
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id === entity.entities_id);
      return parent ? parent.name : "";
    },
    loadData() {
      if (this.active) {
        USER_API.loadDoc()
          .then(response => {
            const docs = response.data;
            this.documentsList = docs;
            /*.filter(
              doc => doc.entities_id === this.$route.params.id
            );*/
          })
          .finally(() => {
            this.loadingDoc = false;
          });
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (
                data[i].content.substring(0, 5) ===
                this.getUserId.toString().padStart(5, "0")
              ) {
                this.note = data[i].content.substring(5);
                break;
              } else {
                for (let i = 0; i < size; i++) {
                  if (data[i].content.substring(0, 5) === "00000") {
                    this.note = data[i].content.substring(5);
                    break;
                  } else {
                    this.note = "Pas de note enregistrée";
                  }
                }
              }
            }
          } else {
            this.note = null;
          }
        });
      }
    },
    documentIcon(mimeType) {
      if (mimeType === "image/jpeg") {
        return "mdi-folder-image";
      } else if (mimeType === "application/pdf") {
        return "mdi-file-pdf";
      } else {
        return "mdi-folder";
      }
    },
    downloadFile(item) {
      USER_API.downloadDocument(item);
    },
    async openFile(item) {
      USER_API.getDocument(item.id, true).then(async ({ data }) => {
        const file = await blobTob64(data);
        this.src = { data: base64ToString(file) };
      });
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD-MM-YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    },
    checkDocPermission(permission) {
      const permissionSize = permission.length;
      if (permissionSize > 2) {
        const publique = permission[0];
        const occupant = permission[1];
        const investisseur = permission[2];

        let per = false;
        if (publique.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "publique",
            this.getUserProfiles
          );
          if (per) return true;
        }

        if (occupant.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "occupant",
            this.getUserProfiles
          );
          if (per) return true;
        }
        if (investisseur.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "investisseur",
            this.getUserProfiles
          );

          if (per) return true;
        }

        return false;
      }
      return false;
    }
  },
  watch: {
    $route() {
      this.loadData();
    },
    active() {
      this.loadData();
    },
    filteredDocList() {
      if (this.filteredDocList && this.filteredDocList.length > 0) {
        this.openFile(this.filteredDocList[0]);
      }
    }
  }
};
</script>
<style scoped>
.preview__pdf {
  max-height: 400px;
  overflow-y: scroll;
}
</style>
