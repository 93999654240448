import VDaterange from "../VDaterange";
import moment from "../../plugins/moment";

export const DocFilterMixin = {
  props: {
    dateRange: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: { VDaterange },
  data() {
    return {
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: [
        {
          label: this.$t("index.today"),
          range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        {
          label: this.$t("index.yesterday"),
          range: [
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: this.$t("index.last_days"),
          range: [
            moment()
              .subtract(30, "days")
              .format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD")
          ]
        },
        {
          label: "Dernier mois",
          range: [
            moment()
              .subtract(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: "Mois en cours",
          range: [
            moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            moment()
              .endOf("month")
              .format("YYYY-MM-DD")
          ]
        }
      ]
    };
  },
  computed: {
    ranges: {
      get: function() {
        return this.dateRange;
      },
      set: function(val) {
        this.$emit("updatedranges", val);
      }
    }
  }
};
