<template>
  <div>
    <v-btn
      class="mb-mt-6"
      color="primary"
      @click="dialog = true"
      absolute
      right
    >
      Add document
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title> Add new document </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-file-input
                v-model="document"
                label="Document file"
              ></v-file-input
            ></v-row>
            <v-row
              ><v-col cols="12" md="6">
                <v-text-field
                  v-model="docName"
                  label="Document Name"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  v-model="recursive"
                  :label="this.chexboxName"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addDocument"
            :disabled="!canSendDoc"
          >
            Save
          </v-btn>
        </v-card-actions>
        <v-overlay absolute :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import USER_API from "../../api/user";
import axios from "../../plugins/axios";

export default {
  data() {
    return {
      loadingDoc: false,
      dialog: false,
      document: null,
      docCategorie: 14,
      docName: null,
      recursive: true,
      loading: false,
      commentLoading: false,
      valid: true,
      chexboxName: ""
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["getActiveEntity"]),
    canSendDoc() {
      return this.docName && this.document && this.valid;
    }
  },
  methods: {
    loadData() {
      if (this.getActiveEntity.completename) {
        this.chexboxName =
          "commun à " + this.getActiveEntity.completename.split(">")[3];
      } else this.chexboxName = "commun à tous";
    },
    async addDocument() {
      this.loading = true;
      if (this.recursive === true) {
        await USER_API.setActiveEntity(this.getActiveEntity.entities_id);
      }
      USER_API.addNewDocument(
        this.document,
        this.docName,
        this.docCategorie,
        this.recursive
      )
        .then(({ data }) => {
          USER_API.getDocument(data.id).then(response => {
            const document = response.data;
            this.$emit("newdocadded", document);
          });
        })
        .catch(({ response }) => {
          axios.defaults.headers["Content-Type"] = "application/json";
          this.setError(response.data[1]);
        })
        .finally(() => {
          //USER_API.setActiveEntity(this.getActiveEntity.id);
          this.loading = false;
          this.dialog = false;
          this.finalImg = undefined;
          this.document = null;
          this.docName = null;
          this.recursive = true;
        });
    }
  },
  watch: {
    document() {
      this.$refs.form.validate();
    }
  }
};
</script>
