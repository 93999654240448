<template>
  <v-card flat>
    <v-card v-if="isMobile" flat>
      <v-row class="mx-3" no-gutters>
        <v-col md="4" class="pt-5">
          <bienvenue-editor
            :comment="note_bienvenue"
            title="Consignes de bienvenue"
          >
          </bienvenue-editor>
        </v-col>
      </v-row>
      <v-row class="mx-3" no-gutters>
        <v-col md="4" class="pt-5">
          <consigne-editor :comment="note" title="Consignes de départ">
          </consigne-editor>
        </v-col>
      </v-row>
      <v-row class="mx-3" no-gutters>
        <v-col md="4" class="pt-5">
          <menage-editor
            :comment="note_menage"
            title="Consignes additionnelles pour le ménage"
          >
          </menage-editor>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-card flat class="mt-4 elevation-2">
        <v-card-title>
          <label class="switch mr-4">
            <input type="checkbox" @click="toggleCheckbox" />
            <div class="slider round"></div>
          </label>
          Mail d'accueil</v-card-title
        >
        <v-row class="mx-3" no-gutters v-if="this.checkbox">
          <v-col md="6">
            <i>Mail envoyé au locataire suite au scan du porte clé</i>
            <bienvenue-editor :comment="note_bienvenue" title="Mail général">
            </bienvenue-editor>
          </v-col>
          <v-col md="6">
            <i>
              Mail alternatif envoyé au locataire suite au scan du porte clé
            </i>
            <bienvenue-alt-editor
              :comment="note_bienvenue_alt"
              title="Mail alternatif"
            >
            </bienvenue-alt-editor>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat class="mt-4 elevation-2">
        <v-card-title>
          <label class="switch mr-4">
            <input type="checkbox" @click="toggleCheckbox2" />
            <div class="slider round"></div>
          </label>
          Info logement</v-card-title
        >
        <v-row class="mx-3" no-gutters v-if="this.checkbox2">
          <v-col md="6">
            <i>Information logement pour tous les logements</i>
            <infolgt-editor :comment="info_main" title="Texte principal">
            </infolgt-editor>
          </v-col>
          <v-col md="6">
            <i>
              Information logement alternative pour tous les logements
            </i>
            <infolgt-alt-editor :comment="info_alt" title="Texte alternatif">
            </infolgt-alt-editor>
          </v-col>
        </v-row>
      </v-card>
      <v-card flat class="mt-4 elevation-2">
        <v-card-title>
          <label class="switch mr-4">
            <input type="checkbox" @click="toggleCheckbox3" />
            <div class="slider round"></div>
          </label>
          Consigne de départ
          <v-spacer></v-spacer>
          <v-btn
            fab
            elevation="1"
            small
            @click="clickedDepart()"
            dark
            color="white"
            title="Afficher la note"
          >
            <v-icon color="primary" large>mdi-cellphone-screenshot</v-icon>
          </v-btn></v-card-title
        >
        <v-row class="mx-3" no-gutters v-if="this.checkbox3">
          <v-col md="6">
            <i>Consignes lors du check out</i>
            <consigne-editor :comment="note" title="Consignes de départ">
            </consigne-editor>
          </v-col>
          <v-col md="6">
            <i>
              Consignes additionnelles en cas de ménage par le locataire
            </i>
            <menage-editor
              :comment="note_menage"
              title="Consignes additionnelles pour le ménage"
            >
            </menage-editor>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import USER_API from "../../api/user";
import ConsigneEditor from "../editor/ConsigneEditor";
import MenageEditor from "../editor/MenageEditor";
import BienvenueEditor from "../editor/BienvenueEditor";
import BienvenueAltEditor from "../editor/BienvenueAltEditor";
import InfolgtEditor from "../editor/InfolgtEditor";
import InfolgtAltEditor from "../editor/InfolgtAltEditor";
import { PORTAL_CONFIG } from "../../config";

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConsigneEditor,
    MenageEditor,
    BienvenueEditor,
    BienvenueAltEditor,
    InfolgtEditor,
    InfolgtAltEditor
  },
  data() {
    return {
      activeEntity: null,
      note: "",
      note_menage: "",
      note_bienvenue: "",
      info_main: "",
      info_alt: "",
      note_bienvenue_alt: "",
      checkbox: false,
      checkbox2: false,
      checkbox3: false
    };
  },
  computed: {
    ...mapGetters(["getUserProfiles", "getUserId", "getEntity"]),

    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  created() {
    this.note = "merci de patienter";
    this.note_menage = "merci de patienter";
    this.note_bienvenue = "merci de patienter";
    this.note_bienvenue_alt = "merci de patienter";
    this.info_main = "merci de patienter";
    this.info_alt = "merci de patienter";
    this.loadTouristicNote();
    this.loadMenageNote();
    this.loadBienvenueNote();
    this.loadBienvenueAltNote();
    this.loadInfoMain();
    this.loadInfoAlt();
  },
  methods: {
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id === entity.entities_id);
      return parent ? parent.name : "";
    },
    loadTouristicNote() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "CCCCC") {
                this.note = data[i].content.substring(5);
                break;
              } else {
                this.note = "Pas de consigne";
              }
            }
          } else {
            this.note = "Pas de consigne";
          }
        });
      });
    },
    loadMenageNote() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "DDDDD") {
                this.note_menage = data[i].content.substring(5);
                break;
              } else {
                this.note_menage = "Pas de consigne - l'agence fait le ménage";
              }
            }
          } else {
            this.note_menage = "Pas de consigne - l'agence fait le ménage";
          }
        });
      });
    },
    loadBienvenueNote() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "EEEEE") {
                this.note_bienvenue = data[i].content.substring(5);
                break;
              } else {
                this.note_bienvenue = "Pas de mail d'accueil";
              }
            }
          } else {
            this.note_bienvenue = "Pas de mail d'accueil";
          }
        });
      });
    },
    loadBienvenueAltNote() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "HHHHH") {
                this.note_bienvenue_alt = data[i].content.substring(5);
                break;
              } else {
                this.note_bienvenue_alt = "Pas de mail d'accueil alternatif";
              }
            }
          } else {
            this.note_bienvenue_alt = "Pas de mail d'accueil alternatif";
          }
        });
      });
    },
    loadInfoMain() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "GGGGG") {
                this.info_main = data[i].content.substring(5);
                break;
              } else {
                this.info_main = "Pas de message de principal";
              }
            }
          } else {
            this.info_main = "Pas de message de principal";
          }
        });
      });
    },
    loadInfoAlt() {
      USER_API.changeActiveEntity().then(() => {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) == "FFFFF") {
                this.info_alt = data[i].content.substring(5);
                break;
              } else {
                this.info_alt = "Pas de message alternatif";
              }
            }
          } else {
            this.info_alt = "Pas de message alternatif";
          }
        });
      });
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    },
    toggleCheckbox() {
      this.checkbox = !this.checkbox;
      this.$emit("setCheckboxVal", this.checkbox);
    },
    toggleCheckbox2() {
      this.checkbox2 = !this.checkbox2;
      this.$emit("setCheckboxVal", this.checkbox2);
    },
    toggleCheckbox3() {
      this.checkbox3 = !this.checkbox3;
      this.$emit("setCheckboxVal", this.checkbox3);
    },
    clickedDepart() {
      let alignement = this.$vuetify.breakpoint.width - 500;
      const windowFeatures = "left=" + alignement + ",width=500,height=800";
      window.open(
        PORTAL_CONFIG.SERVER_WEB +
          "/" +
          PORTAL_CONFIG.APP_NAME +
          "/" +
          PORTAL_CONFIG.CLIENT_NAME +
          "/html/lt_precheckout.php?pass=ok&entity=" +
          this.$route.params.id,
        "_blank",
        windowFeatures
      );
    }
  },

  watch: {
    $route() {
      this.note = "merci de patienter";
      this.note_menage = "merci de patienter";
      this.note_bienvenue = "merci de patienter";
      this.note_bienvenue_alt = "merci de patienter";
      this.info_main = "merci de patienter";
      this.info_alt = "merci de patienter";
      this.loadTouristicNote();
      this.loadMenageNote();
      this.loadBienvenueNote();
      this.loadBienvenueAltNote();
      this.loadInfoMain();
      this.loadInfoAlt();
    },
    active() {
      this.loadTouristicNote();
      this.loadMenageNote();
      this.loadBienvenueNote();
      this.loadBienvenueAltNote();
      this.loadInfoMain();
      this.loadInfoAlt();
    }
  }
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--v-primary-base);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--v-primary-base);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
