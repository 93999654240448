<template>
  <v-col cols="12">
    <v-card class="mt-6" flat min-width="300px">
      <v-card-title class="headline display-1">
        {{ $t("tableTitle.list_user") }}
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          fab
          elevation="1"
          small
          :href="exportLink"
          dark
          color="white"
          target="_blank"
          title="Exporter en csv"
        >
          <v-icon color="primary">mdi-file-delimited</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('tableStatus.searchAll')"
          single-line
          hide-details
          class="mt-n2"
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          sort-by="date_creation"
          sort-desc
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:item="{ item }">
            <c-row
              :item="item"
              :headers="headers"
              rtl
              ignore-null-val
            ></c-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import { mapGetters } from "vuex";
import { Decoder } from "../../mixins/decodeContent";
import { PORTAL_CONFIG } from "../../config";

export default {
  name: "Data",
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      pageCount: 0,
      search: "",
      loading: false,
      headers: [
        {
          text: this.$t("header.name"),
          align: "left",
          sortable: true,
          value: "lgt_name"
        },
        {
          text: "Date",
          align: "right",
          sortable: true,
          value: "date_click"
        },
        {
          text: "email",
          align: "right",
          sortable: true,
          value: "email"
        },
        {
          text: "Langue",
          align: "left",
          sortable: true,
          value: "langue"
        },
        {
          text: "Module",
          align: "right",
          sortable: false,
          value: "module"
        }
      ]
    };
  },
  mixins: [UserSessionData, Decoder],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken"
    ]),
    exportLink() {
      return `${PORTAL_CONFIG.SERVER_WEB}/clients/${PORTAL_CONFIG.CLIENT_NAME}/analytic.php?entity=${this.getActiveEntity.id}&pass&csv`;
    }
  },
  methods: {
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        fetch(`
        ${PORTAL_CONFIG.SERVER_WEB}/clients/${PORTAL_CONFIG.CLIENT_NAME}/analytic.php?entity=${this.getActiveEntity.id}&pass
        `)
          .then(response => {
            return response.json();
          })
          .then(result => {
            this.list = result;
            /*
          const size = this.list.length;
          console.log(size)
          if (size > 0) {
          for (let i = 0; i < size; i++) {
                if (this.list[i].other) {
                  this.list[i].other = JSON.parse(this.list[i].other);
                }
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
              }
          }*/
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
