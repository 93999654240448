<template>
  <v-col cols="12">
    <v-card class="elevation-3">
      <v-card v-if="isConfig" class="mt-3" flat min-width="300px">
        <v-card-title v-if="isMobile" class="headline display-1">
          <v-col cols="6">
            Clefs
          </v-col>
        </v-card-title>
        <v-card-title v-else class="headline display-1">
          <v-col cols="4">
            Ouverture / Fermeture
          </v-col>
          <v-col cols="4" offset="1">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('tableStatus.searchAll')"
              clearable
              single-line
              hide-details
              class="mt-n2"
            >
            </v-text-field>
          </v-col>
          <!--v-col cols="1" offset="1">
            <vue-json-to-csv
              :json-data="this.filteredItems"
              :labels="labels"
              :csv-title="My_CSV"
            >
              <v-btn color="primary">
                CSV <v-icon small class="ml-6">mdi-export-variant</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-col-->
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selectedClef"
            :headers="headers"
            :items="list"
            item-key="id"
            class="table is_bordered"
            :loading="loading"
            :search="searched"
            :page.sync="page"
            sort-by="date_mod"
            sort-desc
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:item="{ item }">
              <tr :item="item" :headers="headers" rtl>
                <td>
                  {{ item.lgt_name }}
                </td>
                <td>
                  {{ item.last.length > 0 ? item.last[0].last_visit : "- " }}
                </td>
                <td>
                  {{
                    item.last.length > 0 ? item.last[0].last_user_name : "- "
                  }}
                </td>
                <td v-if="item.last[0]">
                  <span
                    class="pa-6"
                    v-html="htmlComment(item.last[0].comment)"
                  ></span>
                </td>
                <td v-else>
                  {{ "- " }}
                </td>
                <td v-if="item.last[1]">
                  {{ item.last.length > 1 ? item.last[1].last_visit : "- " }}
                </td>
                <td v-if="item.last[1]">
                  {{
                    item.last.length > 1 ? item.last[1].last_user_name : "- "
                  }}
                </td>
                <td v-if="item.last[1]">
                  <span
                    class="pa-6"
                    v-html="htmlComment(item.last[1].comment)"
                  ></span>
                </td>
                <td v-if="item.last[2]">
                  {{ item.last.length > 2 ? item.last[2].last_visit : "- " }}
                </td>
                <td v-if="item.last[2]">
                  {{
                    item.last.length > 2 ? item.last[2].last_user_name : "- "
                  }}
                </td>
                <td v-if="item.last[2]">
                  <span
                    class="pa-6"
                    v-html="htmlComment(item.last[2].comment)"
                  ></span>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </div>
        </v-card-text>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </v-card>
      <v-card v-else flat>
        A configurer
      </v-card>
    </v-card>
  </v-col>
</template>
<script>
import { statusListKeys } from "../../utils";
import { getReservationState } from "../../utils/entity";
import { UserSessionData } from "../../mixins/userData";
import OBJECT_API from "../../api/object";
import { mapGetters } from "vuex";
//import VueJsonToCsv from "vue-json-to-csv";
import { PORTAL_CONFIG } from "../../config";
import moment from "moment-timezone";

export default {
  name: "OngletKetchp",
  components: {
    //VueJsonToCsv
  },
  data() {
    return {
      selectedClef: [],
      page: 1,
      slider: true,
      itemsPerPage: 1500,
      pageCount: 0,
      search: "",
      loading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      list: [],
      headersSup: [],
      isConfig: false,
      statusList: ["AGENCE", "PRESTATAIRE", "LOCATAIRE", "A REFAIRE", "PERDUE"],
      selectedStatus: [],
      labels: {
        lgt_name: { title: "Logement" },
        last_user_name: { title: "Qui?" },
        last_visit: { title: "Quand?" },
        comment: { title: "Commentaire" }
      },
      My_CSV: "clefs",
      choix: "ddd DD/MM - HH:mm",
      choix2: "ddd DD MMM"
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "getAllTickets",
      "getSessionToken",
      "getUsers",
      "getUserId"
    ]),
    headers() {
      let headers = [
        {
          text: "LOGEMENT",
          align: "left",
          sortable: true,
          value: "lgt_name"
        }
      ];
      this.headersSup.forEach(el => {
        headers.push({
          text: el.value.toUpperCase(),
          align: "left",
          sortable: false,
          value: "last"
        });
        headers.push({
          text: "QUI ?",
          align: "left",
          sortable: false
        });
        headers.push({
          text: "COMMENTAIRE",
          align: "left",
          sortable: false
        });
      });
      return headers;
    },
    filteredItems() {
      return this.list;
    },
    searched() {
      if (this.search && this.search.includes(" ")) {
        let nbMots = 0;
        const space = / /g;
        let searches = [];
        while (space.exec(this.search)) {
          nbMots++;
        }
        for (let i = 0; i < nbMots; i++) {
          searches[i] = this.search.split(" ")[i];
        }
        if (this.search.split(" ")[nbMots]) {
          nbMots++;
          searches[nbMots - 1] = this.search.split(" ")[nbMots - 1];
        }
        if (nbMots == 2) {
          return searches[0] && searches[1];
        }
        if (nbMots == 3) {
          return searches[0] && searches[1] && searches[2];
        }
      }
      return this.search;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        let ent = this.$route.params.id;
        //this.list = JSON.parse(OBJECT_API.getKeys(this.getSessionToken, ent));
        this.list = JSON.parse(
          OBJECT_API.getKetchup(this.getSessionToken, ent)
        )[ent];
        this.headersSup = JSON.parse(
          OBJECT_API.getKetchup(this.getSessionToken, ent)
        )["type"];
        if (this.headersSup) {
          this.isConfig = true;
        }
        this.loading = false;
      }
    },
    clicked: function(row) {
      let alignement = this.$vuetify.breakpoint.width - 500;
      const windowFeatures = "left=" + alignement + ",width=500,height=800";
      window.open(
        PORTAL_CONFIG.SERVER_WEB +
          "/" +
          PORTAL_CONFIG.APP_NAME +
          "/" +
          PORTAL_CONFIG.CLIENT_NAME +
          "/loader.php?script=ticket_it&user=" +
          this.getUserToken +
          "&code_pi=" +
          row.code_pi +
          "&lot=key",
        "_blank",
        windowFeatures
      );
    },
    clickSearched(row) {
      this.search = row.lgt_name;
    },
    getStatus(status) {
      return status
        ? statusListKeys.find(el => el.state === parseInt(status))
        : { name: "" };
    },
    formatText(id) {
      if (id.date_mod > localStorage.getItem("last2Refresh")) {
        return "<b>> " + id.name + "</b>";
      } else return id.name;
    },
    getStatusLgt(lgtstatus) {
      return getReservationState(lgtstatus);
    },
    formatDateH(date) {
      return moment(date).format(this.choix);
    },
    formatDate(date) {
      return moment(date).format(this.choix2);
    },
    htmlComment(comment) {
      let text = "";
      comment.forEach(el => {
        let commentaire = "RAS";
        el.comment ? (commentaire = el.comment) : "";
        text = text + "<p>" + el.name + " : " + commentaire;
      });
      return text;
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
<style>
/* Modifier la valeur de max-height pour fixer la hauteur des lignes du tableau */

@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    vertical-align: middle !important;
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
tr {
  height: 3em !important;
}
</style>
