<template>
  <v-col cols="12">
    <v-card class="mt-6">
      <v-card-title class="headline display-1">
        {{ $t("header.work") }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <c-row :item="item" :headers="headers" rtl ignore-null-val></c-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import USER_API from "../../api/user";
export default {
  name: "Travaux",
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      loading: false,
      headers: [
        {
          text: this.$t("header.name"),
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("header.note"),
          align: "left",
          sortable: false,
          value: "comment"
        },
        {
          text: this.$t("header.perimeter"),
          align: "left",
          sortable: false,
          value: "ger"
        },
        {
          text: this.$t("header.description"),
          align: "left",
          sortable: false,
          value: "details"
        },

        {
          text: this.$t("header.budget_n_1"),
          align: "right",
          sortable: false,
          value: "afaire",
          isNumber: true
        },
        {
          text: this.$t("header.budget"),
          align: "right",
          sortable: false,
          value: "budget",
          isNumber: true
        },
        {
          text: this.$t("header.realize_last_years"),
          align: "right",
          sortable: false,
          value: "realise",
          isNumber: true
        }
      ]
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getTravaux()
          .then(response => {
            this.list = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
