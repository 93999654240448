<template>
  <v-card v-if="isSmallMobile" min-width="340" class="elevation-0">
    <v-tabs
      class="tabs-border"
      right=""
      active-class="active-tab"
      hide-slider
      show-arrows
    >
      <v-tab
        class="tabsmall"
        v-for="(item, index) in allowedOnglets"
        :key="index"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
      <v-tab-item v-for="item in allowedOnglets" disabled :key="item.number">
        <onglet-synthese-carto v-if="item.number === 1"></onglet-synthese-carto>
        <onglet-synthese-technique
          :active="isActivate"
          v-if="item.number === 2"
        ></onglet-synthese-technique>
        <onglet-prestation
          :note="prestanote"
          v-if="item.number === 3"
        ></onglet-prestation>
        <onglet-economie
          :active="isActivate"
          v-if="item.number === 4"
        ></onglet-economie>
        <onglet-document
          :active="isActivate"
          :note="touristicnote"
          v-if="item.number === 5"
        ></onglet-document>
        <onglet-site-life v-if="item.number === 6"></onglet-site-life>
        <onglet-access
          :active="isActivate"
          v-if="item.number === 7"
        ></onglet-access>
        <onglet-info
          :active="isActivate"
          v-if="item.number === 9"
        ></onglet-info>
        <onglet-resa
          :active="isActivate"
          v-if="item.number === 10"
        ></onglet-resa>
        <onglet-data
          :active="isActivate"
          v-if="item.number === 11"
        ></onglet-data>
        <onglet-consigne
          :active="isActivate"
          v-if="item.number === 12"
        ></onglet-consigne>
        <onglet-clef
          :active="isActivate"
          v-if="item.number === 13"
        ></onglet-clef>
        <onglet-ketchup
          :active="isActivate"
          v-if="item.number === 14"
        ></onglet-ketchup>
      </v-tab-item>
    </v-tabs>
  </v-card>
  <v-row v-else align="start" justify="center" class="fill-height">
    <v-col cols="12">
      <v-card class="elevation-0">
        <v-tabs
          class="tabs-border"
          right=""
          active-class="active-tab"
          hide-slider
          show-arrows
          v-model="selectTab"
        >
          <template v-if="isMobile">
            <v-tab v-for="(item, index) in allowedOnglets" :key="index">
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
          <template v-else>
            <v-tab
              @click="clickTab(item)"
              v-for="(item, index) in allowedOnglets"
              :key="index"
              class="text-left"
            >
              <v-icon left>{{ item.icon }}</v-icon>
              {{ item.name }}
              <v-icon
                @click="clickTab2(item)"
                color="primary"
                class="ml-1 mr-n2"
                >{{ item.icon2 }}
              </v-icon>
            </v-tab>
          </template>
          <v-btn
            fab
            elevation="1"
            small
            :href="redirectLink"
            dark
            color="white"
            target="_blank"
            title="Ajouter une intervention"
            v-if="this.completeName && redirectLink"
          >
            <v-icon color="primary" large>mdi-qrcode</v-icon> </v-btn
          ><i class="pa-1"></i>
          <v-btn
            fab
            elevation="1"
            small
            :href="redirectLink2"
            dark
            color="white"
            target="_blank"
            title="Ajouter un index de comptage"
            v-if="this.completeName && show"
          >
            <v-icon color="primary" large>mdi-chart-bar</v-icon>
          </v-btn>
          <i class="pa-1"></i>
          <v-btn
            fab
            elevation="1"
            small
            :href="redirectLink3"
            dark
            color="white"
            target="_blank"
            title="Réserver une ressource"
            v-if="this.completeName && show"
          >
            <v-icon color="primary" large>mdi-calendar-cursor</v-icon>
          </v-btn>
          <i class="pa-1"></i>
          <v-tab-item
            v-for="item in allowedOnglets"
            disabled
            :key="item.number"
          >
            <onglet-synthese-carto
              v-if="item.number === 1"
            ></onglet-synthese-carto>
            <onglet-synthese-technique
              :active="isActivate"
              v-if="item.number === 2"
            ></onglet-synthese-technique>
            <onglet-prestation
              :note="prestanote"
              v-if="item.number === 3"
            ></onglet-prestation>
            <onglet-economie
              :active="isActivate"
              v-if="item.number === 4"
            ></onglet-economie>
            <onglet-document
              :active="isActivate"
              v-if="item.number === 5"
            ></onglet-document>
            <onglet-touristic
              :active="isActivate"
              v-if="item.number === 6"
            ></onglet-touristic>
            <onglet-site-life v-if="item.number === 7"></onglet-site-life>
            <onglet-access
              :active="isActivate"
              v-if="item.number === 8"
            ></onglet-access>
            <onglet-info
              :active="isActivate"
              v-if="item.number === 9"
            ></onglet-info>
            <onglet-resa
              :active="isActivate"
              v-if="item.number === 10"
            ></onglet-resa>
            <onglet-data
              :active="isActivate"
              v-if="item.number === 11"
            ></onglet-data>
            <onglet-consigne
              :active="isActivate"
              v-if="item.number === 12"
            ></onglet-consigne>
            <onglet-clef
              :active="isActivate"
              v-if="item.number === 13"
            ></onglet-clef>
            <onglet-ketchup
              :active="isActivate"
              v-if="item.number === 14"
            ></onglet-ketchup>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { permissions, devEntity } from "../utils";
import USER_API from "../api/user";
import OngletEconomie from "../components/economie/OngletEconomie";
import OngletSyntheseTechnique from "../components/synthese/OngletSyntheseTechnique";
import OngletSiteLife from "../components/site/OngletSiteLife";
import OngletPrestation from "../components/synthese/OngletSynthesePrestation";
import OngletDocument from "../components/document/Index";
import OngletTouristic from "../components/touristic/Index";
import OngletConsigne from "../components/consigne/Index";
import OngletAccess from "../components/access/OngletAccess";
import OngletInfo from "../components/info/Info";
import OngletData from "../components/data/Data";
import OngletSyntheseCarto from "../components/synthese/OngletSyntheseCarto";
import OngletClef from "../components/clef/PluginGenericobjectClef";
import OngletResa from "../components/reservation/OngletResa";
import OngletKetchup from "../components/ketchup/OngletKetchup";
import { PORTAL_CONFIG } from "../config";

export default {
  components: {
    OngletEconomie,
    OngletSyntheseTechnique,
    OngletSiteLife,
    OngletPrestation,
    OngletDocument,
    OngletTouristic,
    OngletConsigne,
    OngletAccess,
    OngletSyntheseCarto,
    OngletClef,
    OngletInfo,
    OngletData,
    OngletResa,
    OngletKetchup
  },
  data() {
    return {
      tab: null,
      completeName: "",
      isActivate: false,
      prestanote: null,
      touristicnote: null,
      selectTab: 0
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "isAdminUser",
      "getNewIncidentCount",
      "getNewLgtCount",
      "getNewKeyCount"
    ]),
    onglets() {
      return [
        {
          name: this.name1,
          icon: "mdi-map-marker-multiple",
          icon2: this.icone1,
          number: 1
        },
        {
          name: this.name2,
          icon: "mdi-wrench",
          icon2: this.icone2,
          number: 2
        },
        {
          name: "Prestation",
          icon: "mdi-broom",
          number: 3
        },
        {
          name: "Economique",
          icon: "mdi-currency-eur",
          number: 4
        },
        {
          name: "Document",
          icon: "mdi-file",
          number: 5
        },
        {
          name: "Touristique",
          icon: "mdi-information-variant-box",
          number: 6
        },
        {
          name: "Calendrier",
          icon: "mdi-calendar-plus",
          number: 7
        },
        {
          name: "Acces",
          icon: "mdi-key",
          number: 8
        },
        {
          name: "Info",
          icon: "mdi-information",
          number: 9
        },
        {
          name: "Résa",
          icon: "mdi-calendar-check",
          number: 10
        },
        {
          name: "Data",
          icon: "mdi-database",
          number: 11
        },
        {
          name: "Consignes",
          icon: "mdi-briefcase-check",
          number: 12
        },
        {
          name: this.name13,
          icon: "mdi-key",
          icon2: this.icone13,
          number: 13
        },
        {
          name: "CheckUp",
          icon: "mdi-check",
          number: 14
        }
      ];
    },
    selectedEntity() {
      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    allowedOnglets() {
      if (this.getActiveEntity.id === devEntity) {
        return this.onglets.filter(item => item.number == 2);
      }
      return this.onglets.filter(item => this.displaySection(item.number));
    },
    redirectLink() {
      //si on est sur l'entité dev on utilise le token du user
      if (this.getActiveEntity.id == devEntity) {
        return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
      }
      //s'il y a un token visiteur sur l'entité de niveau 5
      if (this.getActiveEntity.fax) {
        let visitToken = JSON.parse(this.getActiveEntity.fax).user[0];
        return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${visitToken}&entity=${this.$route.params.id}`;
      } else {
        //S'il y a un token visiteur sur l'entité de niveau 4
        let parentEntity = this.getEntity.filter(
          entity =>
            entity.level === 4 && entity.id === this.getActiveEntity.entities_id
        );
        if (parentEntity[0].fax) {
          let visitToken = JSON.parse(parentEntity[0].fax).user[0];
          return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${visitToken}&entity=${this.$route.params.id}`;
        } else {
          //S'il y a un token visiteur sur l'entité de niveau 3
          let grandparentEntity = this.getEntity.filter(
            entity =>
              entity.level === 3 && entity.id === parentEntity[0].entities_id
          );
          if (grandparentEntity[0].fax) {
            let visitToken = JSON.parse(grandparentEntity[0].fax).user[0];
            return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${visitToken}&entity=${this.$route.params.id}`;
          }
        }
      }
      return false;

      //return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    redirectLink2() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/compteur.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    redirectLink3() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/resa.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg
      );
    },
    isSmallMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.sm
      );
    },
    show() {
      return this.isAdminUser;
    },
    name1() {
      let title = "Carto";
      if (+this.getNewLgtCount > 10) {
        title += ` +`;
      }
      return title;
    },
    icone1() {
      if (+this.getNewLgtCount > 10) {
        return "mdi-numeric-10-circle";
      } else if (+this.getNewLgtCount > 0) {
        return `mdi-numeric-${this.getNewLgtCount}-circle`;
      }
      return "";
    },
    name2() {
      let title = "Dégradation";
      if (+this.getNewIncidentCount > 10) {
        title += ` +`;
      }
      return title;
    },
    icone2() {
      if (+this.getNewIncidentCount > 10) {
        return "mdi-numeric-10-circle";
      } else if (+this.getNewIncidentCount > 0) {
        return `mdi-numeric-${this.getNewIncidentCount}-circle`;
      }
      return "";
    },
    name13() {
      let title = "Clef";
      if (+this.getNewKeyCount > 10) {
        title += ` +`;
      }
      return title;
    },
    icone13() {
      if (+this.getNewKeyCount > 10) {
        return "mdi-numeric-10-circle";
      } else if (+this.getNewKeyCount > 0) {
        return `mdi-numeric-${this.getNewKeyCount}-circle`;
      }
      return "";
    }
  },
  methods: {
    ...mapMutations(["setActiveEntity"]),
    displaySection(sectionNumber) {
      let flag = false;
      for (let i = 0; i < this.getUserProfiles.length; i++) {
        if (permissions.shouldDisplay(sectionNumber, this.getUserProfiles[i])) {
          flag = true;
        }
      }
      return flag;
    },
    loadActiveEntity() {
      this.isAuth &&
        USER_API.setActiveEntity(this.$route.params.id).then(() => {
          this.isActivate = true;
          this.setActiveEntity(this.selectedEntity);
          this.completeName = this.selectedEntity.completename.split(">")[1];
          this.reloadUserFullSession();
          if (this.$route.params.id == devEntity) {
            this.loadTouristicNote();
            this.loadPrestaNote();
          }
        });
    },
    reloadUserFullSession() {
      USER_API.getFullSession().then(({ data }) => {
        let userId = data.session.glpiID;
        let { glpigroups } = data.session;
        this.$store.commit("setUserID", userId);
        this.$store.commit("setGLPGroups", glpigroups);
      });
    },
    loadPrestaNote() {
      USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
        const size = data.length;
        if (size > 0) {
          for (let i = 0; i < size; i++) {
            if (data[i].content.substring(0, 5) == "AAAAA") {
              this.prestanote = data[i].content.substring(5);
              break;
            } else {
              this.prestanote = "Pas de note enregistrée";
            }
          }
        } else {
          this.prestanote = "Pas de note enregistrée";
        }
      });
    },
    loadTouristicNote() {
      USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
        const size = data.length;
        if (size > 0) {
          for (let i = 0; i < size; i++) {
            if (data[i].content.substring(0, 5) == "BBBBB") {
              this.touristicnote = data[i].content.substring(5);
              break;
            } else {
              this.touristicnote = "Pas d'information touristique";
            }
          }
        } else {
          this.touristicnote = "Pas d'information touristique";
        }
      });
    },
    clickTab(item) {
      let tab = localStorage.myTabs.split(",").indexOf(item.name);
      localStorage.lastTab = tab;
    },
    clickTab2(item) {
      let tab = localStorage.myTabs.split(",").indexOf(item.name);
      localStorage.lastTab = tab;
      this.$router.go(0);
    }
  },
  mounted() {
    if (!localStorage.getItem("v3"))
      localStorage.removeItem("session_token");
    localStorage.setItem("v3", 1);
    if (this.selectedEntity && this.$route.params.id) {
      if (this.$route.query.tab) {
        this.selectTab = Number(this.$route.query.tab);
      }
      if (localStorage.lastTab) {
        this.selectTab = Number(localStorage.lastTab);
      }
      this.loadActiveEntity();
      //this.loadToast();
      //this.loadTouristicNote(); on les récupère au niveau du module moins d'erreur
      //this.loadPrestaNote();
    }
  },
  watch: {
    selectedEntity() {
      if (this.selectedEntity && this.$route.params.id) {
        this.loadActiveEntity();
      }
    },
    $route() {
      if (this.selectedEntity && this.$route.params.id) {
        this.loadActiveEntity();
        //this.loadTouristicNote();
        //this.loadPrestaNote();
      }
    }
  }
};
</script>
<style>
@media only screen and (max-width: 539px) and (min-width: 240px) {
  .active-tab::before {
    opacity: 0.12 !important;
    margin: 30px;
  }
}
@media only screen and (max-width: 2800px) and (min-width: 540px) {
  .active-tab::before {
    opacity: 0.12 !important;
  }
}
.tabs-border .v-slide-group__content {
  border-bottom: 2px solid !important;
}
.tabsmall {
  margin: -20px;
}
</style>
