<template>
  <v-col cols="12">
    <v-card class="mt-3">
      <v-card-title class="headline display-1">
        {{ $t("header.rental") }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <c-row :item="item" :headers="headers" rtl ignore-null-val></c-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import USER_API from "../../api/user";
export default {
  name: "Occupants",
  data() {
    return {
      page: 1,
      itemsPerPage: 30,

      loading: false,
      headers: [
        {
          text: this.$t("header.commercial_name"),
          align: "left",
          sortable: false,
          value: "nomcommercialfield"
        },
        {
          text: this.$t("header.surface"),
          align: "end",
          sortable: false,
          value: "surfacemtwofield",
          isNumber: true
        },
        {
          text: this.$t("header.location"),
          align: "left",
          sortable: false,
          value: "etageportefield"
        },
        {
          text: this.$t("header.signatory"),
          align: "center",
          sortable: false,
          value: "signatairedubailfield"
        },
        {
          text: this.$t("header.lease_start"),
          align: "center",
          sortable: false,
          value: "datedbutdebailfield"
        },
        {
          text: this.$t("header.lease_end"),
          align: "center",
          sortable: false,
          value: "datefindebailfield"
        },
        {
          text: this.$t("header.termination_date"),
          align: "center",
          sortable: false,
          value: "datelimitedersiliationfield"
        },
        {
          text: this.$t("header.annual_rent"),
          align: "end",
          sortable: false,
          value: "loyerannuelhtfield",
          isNumber: true
        },
        {
          text: this.$t("header.current_balance"),
          align: "center",
          sortable: false,
          value: "soldefield",
          isNumber: true
        }
      ]
    };
  },
  mixins: [UserSessionData],
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getOccupants()
          .then(response => {
            this.list = response.data.filter(
              el => el.items_id === this.$route.params.id
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
