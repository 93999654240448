<template>
  <v-row align="start" justify="center" no-gutters class="fill-height mx-n3">
    <v-col cols="12">
      <v-tabs v-if="isSmallMobile" v-model="tab" show-arrows>
        <v-tab v-for="(type, name) in getStates" :key="type">
          {{
            name === "location" && !type
              ? "Emplace."
              : type.slice(0, 7) + (type.length > 7 ? "." : "")
          }}
        </v-tab>
      </v-tabs>
      <v-tabs v-else v-model="tab" show-arrows>
        <v-tab v-for="(type, name) in getStates" :key="type">
          {{ name === "location" && !type ? "Emplacement" : type }}
        </v-tab>
      </v-tabs>
    </v-col>

    <v-col cols="12">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(name, type) in getStates" disabled :key="type">
          <component :is="getComponentName(type)"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { toComponentName } from "@/utils";
export default {
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapGetters(["getActiveEntity"]),
    getStates() {
      let states = this.getActiveEntity.state;
      states = states ? JSON.parse(states) : { location: "Emplacement" };
      /*const filteredStates = {};
      if (states.location) {
        filteredStates.location = states.location;
      }
      Object.keys(states).forEach(type => {
        if (type !== "location") {
          filteredStates[type] = states[type];
        }
      });*/
      return states;
    },
    isSmallMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.sm
      );
    }
  },
  methods: {
    isLocationType(type) {
      return type === "location";
    },
    getComponentName(type) {
      return toComponentName(type);
    }
  }
};
</script>
