<template>
  <v-col cols="12">
    <v-card class="elevation-3">
      <v-card class="mt-3" flat min-width="300px">
        <v-card-title v-if="isMobile" class="headline display-1">
          <v-col cols="6">
            Clefs
          </v-col>
          <v-col cols="6" class="mt-3 pr-2">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('tableStatus.searchAll')"
              single-line
              hide-details
              class="mt-n2"
            >
            </v-text-field>
          </v-col>
        </v-card-title>
        <v-card-title v-else class="headline display-1">
          <v-col cols="2">
            Liste des clefs :
          </v-col>
          <v-col cols="2" class="subtitle-1 pr-2">
            A l'agence
            <v-btn small fab color="primary">
              {{ this.a }}
            </v-btn>
          </v-col>
          <v-col cols="2" class="subtitle-1 pr-2">
            Prestataire
            <v-btn small fab color="primary">
              {{ this.b }}
            </v-btn>
          </v-col>
          <v-col cols="2" class="subtitle-1 pr-2">
            Locataire
            <v-btn small fab color="primary">
              {{ this.c }}
            </v-btn>
          </v-col>
          <v-col cols="2" class="subtitle-1 pr-2">
            A refaire
            <v-btn small fab color="primary">
              {{ this.d }}
            </v-btn>
          </v-col>
          <v-col cols="2" class="subtitle-1 pr-2">
            Perdues
            <v-btn small fab color="primary">
              {{ this.e }}
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-title class="headline display-1 mt-n12 my-n6">
          <v-col md="4" sm="12">
            <v-select
              label="Filtrer par statut"
              :items="statusList"
              clearable
              v-model="selectedStatus"
              multiple
              chips
            ></v-select>
          </v-col>
          <v-col cols="4" offset="1">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('tableStatus.searchAll')"
              clearable
              single-line
              hide-details
              class="mt-n2"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1" offset="1">
            <vue-json-to-csv
              :json-data="this.filteredItems"
              :labels="labels"
              :csv-title="My_CSV"
            >
              <v-btn color="primary">
                CSV <v-icon small class="ml-6">mdi-export-variant</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </v-col>
        </v-card-title>
        <v-card-title class="subtitle-2">
          <v-col v-if="!this.searched" md="4" sm="12">
            {{
              filteredItems.length > 1
                ? filteredItems.length + " clefs (filtrées) "
                : filteredItems.length + " clef (filtré) "
            }}
            /
            {{
              selectedClef.length > 1
                ? selectedClef.length + " clefs sélectionnées"
                : selectedClef.length + " clef sélectionnée"
            }}
          </v-col>
          <v-col v-else md="4" sm="12">
            {{
              selectedClef.length > 1
                ? selectedClef.length + " clefs sélectionnées"
                : selectedClef.length + " clef sélectionnée"
            }}
          </v-col>
          <v-col cols="2" offset="6" class="mt-n1">
            Dates
            <toggle-button
              class="mt-n1"
              :value="true"
              :width="80"
              :color="{
                checked: 'var(--v-primary-base)',
                unchecked: 'var(--v-primary-base)'
              }"
              :labels="{ checked: 'lisibles', unchecked: 'triables' }"
              @change="changeChoix()"
            />
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selectedClef"
            :headers="headers"
            :items="filteredItems"
            item-key="id"
            class="table is_bordered"
            :loading="loading"
            show-select
            :search="searched"
            :page.sync="page"
            sort-by="date_mod"
            sort-desc
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:header.data-table-select="{ props, on }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-avatar class="white ml-1" size="32">
                      <v-icon color="primary">menu</v-icon>
                    </v-avatar>
                  </v-btn>
                </template>
                <v-list class="pa-0" light>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="selectAll()"
                  >
                    Sélectionner tout
                  </v-list-item>
                  <v-list-item
                    @click="unselectAll()"
                    :disabled="selectedClef.length === 0"
                  >
                    Effacer sélection
                  </v-list-item>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="changeKey(selectedClef, 'statut')"
                    :disabled="selectedClef.length != 1"
                  >
                    Changer le statut
                  </v-list-item>
                  <v-list-item
                    @click="changeKey(selectedClef, 'nom')"
                    :disabled="selectedClef.length != 1"
                  >
                    Changer le nom
                  </v-list-item>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="changeDateKey(selectedClef)"
                    :disabled="selectedClef.length != 1"
                  >
                    Changer la date de retour
                  </v-list-item>
                  <v-list-item
                    @click="giveKeys(selectedClef)"
                    :disabled="selectedClef.length === 0"
                  >
                    Donner à un prestataire
                  </v-list-item>
                  <v-list-item
                    style="background-color: rgb(240, 240, 240);"
                    @click="returnKeys(selectedClef)"
                    :disabled="selectedClef.length === 0"
                  >
                    Retour multiple agence
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item="{ item }">
              <tr :item="item" :headers="headers" rtl>
                <td>
                  <v-checkbox
                    :key="item.id"
                    v-model="selectedClef"
                    :value="item"
                    class="mb-n5 mt-n1"
                  ></v-checkbox>
                </td>
                <td @click="clicked(item)">
                  {{ getStatus(item.status).name }}
                </td>
                <td>
                  <v-tooltip open-delay="500" top color="#333F48">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="elevation-0 mt-n1"
                        :color="
                          item.lgt_state === '3'
                            ? 'primary'
                            : item.lgt_state === '2'
                            ? 'green'
                            : item.lgt_state == '4'
                            ? 'orange'
                            : 'grey'
                        "
                        >{{ mdiStatus(item.lgt_state) }}
                      </v-icon>
                    </template>
                    <span class="pa-6">{{ getStatusLgt(item.lgt_state) }}</span>
                  </v-tooltip>
                  <v-tooltip open-delay="1000" top color="#333F48">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="elevation-0"
                        color="rgba(0, 0, 0, 0)"
                        small
                        @click="clickSearched(item)"
                      >
                        {{ item.lgt_name }}
                      </v-btn>
                    </template>
                    <span class="pa-6">
                      Cliquez pour filtrer sur le nom du logement
                    </span>
                  </v-tooltip>
                </td>
                <td @click="clicked(item)" v-html="formatText(item)"></td>
                <td @click="clicked(item)">
                  {{ formatMAJDate(item.date_mod) }}
                </td>
                <td @click="clicked(item)">
                  {{ item.code_pi.slice(-3) }}
                </td>
                <td @click="clicked(item)">
                  {{ item.last_user }}
                </td>
                <td @click="clicked(item)">
                  {{
                    item.last_user_date ? formatDateH(item.last_user_date) : "-"
                  }}
                </td>
                <td @click="clicked(item)">
                  {{
                    item.status != 1
                      ? item.back
                        ? formatDate(item.back)
                        : "-"
                      : "-"
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </div>
        </v-card-text>
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-card>
  </v-col>
</template>
<script>
import { statusListKeys } from "../../utils";
import { getReservationState } from "../../utils/entity";
import { UserSessionData } from "../../mixins/userData";
import OBJECT_API from "../../api/object";
import { mapGetters } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";
import Swal from "sweetalert2";
import { mySwalClass } from "../../utils/alert";
import { PORTAL_CONFIG } from "../../config";
import moment from "moment-timezone";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "PluginGenericobjectClef",
  components: {
    VueJsonToCsv,
    ToggleButton
  },
  data() {
    return {
      selectedClef: [],
      page: 1,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0,
      slider: true,
      itemsPerPage: 1500,
      pageCount: 0,
      search: "",
      loading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      list: [],
      statusList: ["AGENCE", "PRESTATAIRE", "LOCATAIRE", "A REFAIRE", "PERDUE"],
      selectedStatus: [],
      labels: {
        statusOk: { title: "Statut" },
        lgt_name: { title: "Logement" },
        name: { title: "Nom clef" },
        code_pi: { title: "id" },
        last_user: { title: "Qui?" },
        last_user_date: { title: "Quand?" },
        back: { title: "Retour?" }
      },
      My_CSV: "clefs",
      choix: "ddd DD/MM - HH:mm",
      choix2: "ddd DD MMM"
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "getAllTickets",
      "getSessionToken",
      "getUsers",
      "getUserId"
    ]),
    headers() {
      let headers = [
        {
          text: "Statut",
          align: "left",
          sortable: true,
          value: "status"
        },
        {
          text: "Logement",
          align: "left",
          sortable: true,
          value: "lgt_name"
        },
        {
          text: "Nom clef",
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: "DATE MAJ",
          align: "left",
          sortable: true,
          value: "date_mod"
        },
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "code_pi"
        },
        {
          text: "Qui ?",
          align: "center",
          sortable: true,
          value: "last_user"
        },
        {
          text: "Quand ?",
          align: "center",
          sortable: true,
          value: "last_user_date"
        },
        {
          text: "Retour ?",
          align: "center",
          sortable: true,
          value: "back"
        }
      ];

      return headers;
    },
    filteredItems() {
      this.unselectAll();
      return this.list.filter(i => {
        return (
          this.selectedStatus.length === 0 ||
          this.selectedStatus.includes(this.getStatus(i.status).name)
        );
      });
    },
    searched() {
      this.unselectAll();
      if (this.search && this.search.includes(" ")) {
        let nbMots = 0;
        const space = / /g;
        let searches = [];
        while (space.exec(this.search)) {
          nbMots++;
        }
        for (let i = 0; i < nbMots; i++) {
          searches[i] = this.search.split(" ")[i];
        }
        if (this.search.split(" ")[nbMots]) {
          nbMots++;
          searches[nbMots - 1] = this.search.split(" ")[nbMots - 1];
        }
        if (nbMots == 2) {
          return searches[0] && searches[1];
        }
        if (nbMots == 3) {
          return searches[0] && searches[1] && searches[2];
        }
      }
      return this.search;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        let ent = this.$route.params.id;
        this.list = JSON.parse(OBJECT_API.getKeys(this.getSessionToken, ent));
        this.list.forEach(i => (i.statusOk = this.getStatus(i.status).name));
        let allKeys = this.list;
        this.a = allKeys.filter(i => i.status == 1).length;
        this.b = allKeys.filter(i => i.status == 2).length;
        this.c = allKeys.filter(i => i.status == 3).length;
        this.d = allKeys.filter(i => i.status == 4).length;
        this.e = allKeys.filter(i => i.status == 5).length;
        this.loading = false;
      }
    },
    clicked: function(row) {
      let alignement = this.$vuetify.breakpoint.width - 500;
      const windowFeatures = "left=" + alignement + ",width=500,height=800";
      window.open(
        PORTAL_CONFIG.SERVER_WEB +
          "/" +
          PORTAL_CONFIG.APP_NAME +
          "/" +
          PORTAL_CONFIG.CLIENT_NAME +
          "/loader.php?script=ticket_it&user=" +
          this.getUserToken +
          "&code_pi=" +
          row.code_pi +
          "&lot=key",
        "_blank",
        windowFeatures
      );
    },
    clickSearched(row) {
      this.search = row.lgt_name;
    },
    getStatus(status) {
      return status
        ? statusListKeys.find(el => el.state === parseInt(status))
        : { name: "" };
    },
    formatText(id) {
      if (id.date_mod > localStorage.getItem("last2Refresh")) {
        return "<b>> " + id.name + "</b>";
      } else return id.name;
    },
    formatMAJDate(date) {
      let day = "";
      if (!date) return null;
      if (date.slice(0, 10) == moment().format("YYYY-MM-DD")) {
        day = "auj.";
      } else if (
        date.slice(0, 10) ==
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ) {
        day = "hier";
      } else day = moment(date).format("DD-MM");
      return day + " " + moment(date).format("HH:mm");
    },
    mdiStatus(lgtstatus) {
      let firstLetter = getReservationState(lgtstatus).slice(0, 1);
      return "mdi-alpha-" + firstLetter.toLowerCase() + "-circle";
    },
    getStatusLgt(lgtstatus) {
      return getReservationState(lgtstatus);
    },
    unselectAll() {
      this.selectedClef = [];
    },
    selectAll() {
      if (this.search != "") {
        Swal.fire({
          title: "La sélection multiple ne fonctionne pas avec la recherche.",
          showConfirmButton: false,
          icon: "warning",
          timer: 2500,
          timerProgressBar: true,
          customClass: mySwalClass
        });
      } else {
        let keys = [];
        for (keys in this.filteredItems) {
          this.selectedClef.push(this.filteredItems[keys]);
        }
      }
    },
    async giveKeys(selectedClef) {
      if (selectedClef.length < 25) {
        const today = new Date().toISOString().split("T")[0];
        let keysId = [];
        selectedClef.forEach(key => {
          keysId.push(key.id);
        });
        const { value: formValues } = await Swal.fire({
          title: "Qui ? et date de retour ?",
          html: `
            <input id="swal-input1" class="swal2-input" placeholder="Contact prestataire">
            <input id="swal-input2" class="swal2-input" value="${today}" type="date">
          `,
          focusConfirm: false,
          confirmButtonText: "Confirmer",
          showCancelButton: true,
          customClass: mySwalClass,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").value
            ];
          }
        });
        if (formValues) {
          try {
            selectedClef.forEach(key => {
              key.status = 2;
              key.back = formValues[1];
              key.last_user = formValues[0];
            });
            OBJECT_API.clefsPresta(
              this.getSessionToken,
              keysId,
              formValues[0],
              formValues[1],
              2
            );
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
          this.unselectAll();
          Swal.fire({
            title: "OK",
            showConfirmButton: false,
            icon: "success",
            customClass: mySwalClass,
            timerProgressBar: true,
            timer: 1500
          });
        }
      } else
        Swal.fire({
          title: "Merci de selectionner<br/>moins de 25 clefs.",
          showConfirmButton: false,
          icon: "warning",
          customClass: mySwalClass,
          timerProgressBar: true,
          timer: 1500
        });
    },
    async returnKeys(selectedClef) {
      if (selectedClef.length < 25) {
        const today = new Date().toISOString().split("T")[0];
        const who =
          "Agence / " +
          this.getUsers.find(el => el.id == this.getUserId).firstname +
          " " +
          this.getUsers.find(el => el.id == this.getUserId).realname;
        let keysId = [];
        selectedClef.forEach(key => {
          keysId.push(key.id);
        });
        Swal.fire({
          title: "Confirmer le retour Agence",
          showCancelButton: true,
          confirmButtonText: "Confirmer",
          customClass: mySwalClass
        }).then(result => {
          if (result.isConfirmed) {
            try {
              selectedClef.forEach(key => {
                key.status = 1;
                key.back = "";
                key.last_user_date = today;
                key.last_user = who;
              });
              OBJECT_API.clefsPresta(this.getSessionToken, keysId, who, "", 1);
            } catch ({ response }) {
              this.errors.push({
                title: "Erreur ",
                msg: response.data,
                snackbar: true
              });
            }
            this.unselectAll();
            Swal.fire({
              title: "OK",
              showConfirmButton: false,
              icon: "success",
              customClass: mySwalClass,
              timerProgressBar: true,
              timer: 1500
            });
          }
        });
      } else
        Swal.fire({
          title: "Merci de selectionner<br/>moins de 25 clefs.",
          showConfirmButton: false,
          icon: "warning",
          customClass: mySwalClass,
          timerProgressBar: true,
          timer: 1500
        });
    },
    async changeDateKey(selectedClef) {
      let clefContent = selectedClef[0];
      let formerWhat = "";
      clefContent.back
        ? (formerWhat = clefContent.back.substring(0, 10))
        : (formerWhat = "");
      const { value: formValues } = await Swal.fire({
        title: "Nouveau retour",
        html: `
          <input id="swal-input1" class="swal2-input" value="${formerWhat}" type="date">
        `,
        focusConfirm: false,
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        customClass: mySwalClass,
        preConfirm: () => {
          return [document.getElementById("swal-input1").value];
        }
      });
      if (formValues) {
        try {
          clefContent.back = formValues[0];
          OBJECT_API.clefUpdate(
            this.getSessionToken,
            clefContent.id,
            formValues[0],
            0,
            1,
            0
          );
        } catch ({ response }) {
          this.errors.push({
            title: "Erreur ",
            msg: response.data,
            snackbar: true
          });
        }
        this.unselectAll();
        Swal.fire({
          title: "ok",
          showConfirmButton: false,
          timer: 500
        });
      }
    },
    changeKey(selectedClef, what) {
      let clefContent = selectedClef[0];
      let formerWhat = "";
      switch (what) {
        case "statut":
          formerWhat = clefContent.status;
          break;
        default:
          formerWhat = clefContent.name;
          break;
      }

      Swal.fire({
        title: `Nouveau ${what}`,
        input: what == "statut" ? "select" : "text",
        inputValue: what == "statut" ? `${formerWhat}` - 1 : `${formerWhat}`,
        inputOptions: this.statusList,
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          switch (what) {
            case "statut":
              result.value++;
              clefContent.status = result.value;
              break;
            default:
              clefContent.name = result.value;
              break;
          }
          try {
            OBJECT_API.clefUpdate(
              this.getSessionToken,
              clefContent.id,
              result.value,
              what == "nom" ? 1 : 0,
              0,
              what == "statut" ? 1 : 0
            );
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
          this.unselectAll();
          Swal.fire({
            title: "OK",
            showConfirmButton: false,
            icon: "success",
            customClass: mySwalClass,
            timerProgressBar: true,
            timer: 1500
          });
        }
      });
    },
    changeChoix() {
      this.slider = !this.slider;
      if (this.slider == true) {
        this.choix = "ddd DD/MM - HH:mm";
        this.choix2 = "ddd DD MMM";
      } else {
        this.choix = "YYYY-MM-DD HH:mm:ss";
        this.choix2 = "YYYY-MM-DD";
      }
    },
    formatDateH(date) {
      return moment(date).format(this.choix);
    },
    formatDate(date) {
      return moment(date).format(this.choix2);
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
<style>
/* Modifier la valeur de max-height pour fixer la hauteur des lignes du tableau */

@media only screen and (max-width: 2800px) and (min-width: 800px) {
  .table th {
    vertical-align: middle !important;
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
tr {
  height: 3em !important;
}
</style>
