import VDaterange from "../VDaterange";
import moment from "../../plugins/moment";

export const DocFilterMixin = {
  props: {
    dateRange: {
      type: Object,
      default() {
        return {};
      }
    },
    authorizedDocs: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedCategories: {
      type: Array,
      default() {
        return [];
      }
    },
    selectedNames: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: { VDaterange },
  data() {
    return {
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: [
        {
          label: this.$t("index.today"),
          range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        {
          label: this.$t("index.yesterday"),
          range: [
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: this.$t("index.last_days"),
          range: [
            moment()
              .subtract(30, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        }
      ]
    };
  },
  computed: {
    names: {
      get: function() {
        return this.selectedNames;
      },
      set: function(val) {
        this.$emit("updatednames", val);
      }
    },
    categories: {
      get: function() {
        return this.selectedCategories;
      },
      set: function(val) {
        this.$emit("updatedcategories", val);
      }
    },
    ranges: {
      get: function() {
        return this.dateRange;
      },
      set: function(val) {
        this.$emit("updatedranges", val);
      }
    },
    docNames() {
      return this.authorizedDocs.map(doc => doc[1]);
    },
    docCategories() {
      let categories = this.authorizedDocs.map(doc => {
        if (doc[7] === 0) {
          return this.$t("labels.no_category");
        }
        return doc[7];
      });
      return [...new Set(categories)];
    }
  }
};
