<template>
  <v-container fluid fill-height>
    <v-row align="start" justify="center" class="fill-height" no-gutters>
      <onglet></onglet>
    </v-row>
  </v-container>
</template>
<script>
import Onglet from "../technique/Onglet";
import { mapGetters } from "vuex";

export default {
  name: "OngletSyntheseTechnique",
  components: { Onglet },
  computed: {
    ...mapGetters(["getEntity", "getActiveEntity"]),
    selectedEntity() {
      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    isLocation() {
      let module = this.getEntity.find(ent => ent.id === this.$route.params.id);
      if (module.state) {
        if (!module.state.includes("PluginGenericobject")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
};
</script>
