<template>
  <v-row align="start" justify="center" class="fill-height mx-0">
    <v-tabs v-model="tab" align-with-title>
      <v-tab v-for="type in Object.keys(ticketsItems)" :key="type">
        {{ getTypeName(type) }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tickets, type) in ticketsItems" :key="type">
        <v-row align="start" justify="center" class="fill-height mx-0">
          <v-col cols="12" class="px-0 px-md-0">
            <Prestation
              :loaded="exposedData.loaded"
              :loading="exposedData.loading"
              :tickets="tickets"
              :table-id="'prestationTable__' + type"
            ></Prestation>
          </v-col>
          <v-card-title
            class="headline display-1"
            v-if="!$route.meta.fromEcran"
          >
            PERFORMANCES DU SITE
          </v-card-title>
          <v-col cols="12">
            <tickets-chart
              :tickets="tickets"
              :loaded="exposedData.loaded"
              :search-key="getSearchKey(type)"
              :equip-label="getTypeName(type)"
              v-if="!$route.meta.fromEcran"
            ></tickets-chart>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import Prestation from "./Prestation";
import TicketsChart from "./TicketsChart";
export default {
  name: "TabOnglet",
  props: {
    exposedData: {
      type: Object,
      required: true
    }
  },
  components: { TicketsChart, Prestation },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapGetters(["getActiveEntity"]),
    ticketsItems() {
      let items = {};
      Object.keys(this.getStates).forEach(type => {
        items[type] = this.exposedData.tickets_items[type];
      });
      return items;
    },
    getStates() {
      let states = this.getActiveEntity.state;
      states = states ? JSON.parse(states) : { location: "Emplacement" };
      /*const filteredStates = {};
      if (states.location) {
        filteredStates.location = states.location;
      }
      Object.keys(states).forEach(type => {
        if (type !== "location") {
          filteredStates[type] = states[type];
        }
      });*/
      return states;
    }
  },
  methods: {
    getSearchKey(type) {
      return type.toLowerCase().startsWith("plugingenericobject")
        ? "equipment"
        : "name";
    },
    getTypeName(type) {
      let states = this.getActiveEntity.state;
      states = states ? JSON.parse(states) : states;
      let typeName = states && states[type] ? states[type] : null;
      if (type === "location" && !typeName) {
        return "Emplacement";
      }
      return typeName ? typeName : type;
    }
  }
};
</script>
