<template>
  <default-chart
    :title="title"
    :tickets="tickets"
    :loaded="loaded"
    :status-list="statusListAccess"
    search-key="room"
  ></default-chart>
</template>
<script>
import { statusListAccess } from "../../utils";
import DefaultChart from "./DefaultChart";
import { DefaultChartProps } from "../../mixins/default-chart-props";
export default {
  name: "TicketsChartAccess",
  components: { DefaultChart },
  mixins: [DefaultChartProps],
  data() {
    return {
      statusListAccess: statusListAccess(this.$root.$i18n.locale)
    };
  }
};
</script>
<style scoped>
.chart_ {
  height: 400px !important;
}
</style>
