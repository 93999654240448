<template>
  <v-card flat>
    <v-card v-if="isMobile" flat>
      <doc-filter-mobile
        :dateRange="dateRange"
        @updatedranges="dateRange = $event"
      ></doc-filter-mobile>
      <v-row justify="center">
        <v-col cols="10" class="mt-n4">
          <v-text-field
            cols="8"
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchName')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else>
      <v-row align="start" class="mx-3" no-gutters>
        <v-col md="12" class="pt-5">
          <touristic-editor :comment="note" title="Informations Touristiques">
          </touristic-editor>
        </v-col>
      </v-row>
      <v-card class="elevation-2 mt-6 mx-3 grey lighten-5">
        <v-card-title class="headline display-1">
          {{ $t("index.documents") }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchName')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-card-title>
        <v-card-title>
          <add-new-doc-modal
            :categories="categories"
            @newdocadded="documentsList.push($event)"
          ></add-new-doc-modal>
        </v-card-title>
        <doc-list :docList="docList" :loadingDoc="loadingDoc"></doc-list>
      </v-card>
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "../../plugins/moment";
import USER_API from "../../api/user";
import AddNewDocModal from "./AddNewDocModal.vue";
import DocList from "./DocList.vue";
import TouristicEditor from "../editor/TouristicEditor";

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddNewDocModal,
    DocList,
    TouristicEditor
  },
  data() {
    return {
      documentsList: [],
      loadingDoc: false,
      activeEntity: null,
      search: "",
      dateRange: {},
      categories: [],
      note: ""
    };
  },
  computed: {
    ...mapGetters(["getUserProfiles", "getUserId"]),

    filteredDocList() {
      if (this.documentsList) {
        return this.documentsList.filter(doc => {
          if (doc.documentcategories_id === 0) {
            doc.documentcategories_id = this.$t("labels.no_category");
          }
          const dateCreation = moment(
            doc.date_creation,
            "YYYY-MM-Do HH:mm ss"
          ).format("YYYY-MM-DD");

          return this.filterByDate(dateCreation);
        });
      } else return this.documentsList;
    },
    docList() {
      if (!this.search) return this.filteredDocList;
      return this.filteredDocList.filter(doc => {
        if (doc.documentcategories_id === 0)
          doc.documentcategories_id = this.$t("labels.no_category");
        return (
          doc.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          doc.documentcategories_id
            .toLowerCase()
            .search(this.search.toLowerCase()) > -1
        );
      });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  created() {
    this.loadData();
    this.loadTouristicNote();
  },
  methods: {
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id === entity.entities_id);
      return parent ? parent.name : "";
    },
    loadTouristicNote() {
      USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
        const size = data.length;
        if (size > 0) {
          for (let i = 0; i < size; i++) {
            if (data[i].content.substring(0, 5) == "BBBBB") {
              this.note = data[i].content.substring(5);
              break;
            } else {
              this.note = "Pas d'information touristique";
            }
          }
        } else {
          this.note = "Pas d'information touristique";
        }
      });
    },
    loadData() {
      const touristicCat = 14;
      if (this.active) {
        USER_API.loadSpecificDoc(touristicCat)
          .then(response => {
            const docs = response.data;
            this.documentsList = docs;
            /*.filter(
              doc => doc.entities_id === this.$route.params.id
            );*/
          })
          .finally(() => {
            this.loadingDoc = false;
          });
      }
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    }
  },

  watch: {
    $route() {
      this.loadData();
      this.note = "merci de patienter";
      this.loadTouristicNote();
    },
    active() {
      this.loadData();
      this.loadTouristicNote();
    }
  }
};
</script>
