<template>
  <v-col cols="12" class="mt-n5 mb-n5">
    <v-daterange
      v-model="ranges"
      display-format="dd-MM-yyyy"
      no-title
      :menu-props="menuProps"
      :presets="presets"
      :start-label="$t('labels.start_date')"
      :end-label="$t('labels.end_date')"
      :separator-label="$t('labels.to')"
      :locale="$root.$i18n.locale"
    ></v-daterange>
  </v-col>
</template>
<script>
import { DocFilterMixin } from "./filter.mixins";

export default {
  mixins: [DocFilterMixin]
};
</script>
