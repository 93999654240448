<template>
  <v-col cols="12">
    <v-card class="mt-6">
      <v-card-title class="headline display-1">
        {{ $t("header.levy") }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <c-row :item="item" :headers="headers" rtl ignore-null-val></c-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import USER_API from "../../api/user";
export default {
  name: "Charge",
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      loading: false,
      headers: [
        {
          text: this.$t("header.name"),
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t("header.note"),
          align: "start",
          sortable: false,
          value: "comment"
        },
        {
          text: this.$t("header.creation_date"),
          align: "start",
          sortable: false,
          value: "date_creation"
        },
        {
          text: this.$t("header.nature"),
          align: "start",
          sortable: false,
          value: "nature"
        },
        {
          text: this.$t("header.budget"),
          align: "end",
          sortable: false,
          value: "budget",
          isNumber: true
        },
        {
          text: this.$t("header.engaged"),
          align: "end",
          sortable: false,
          value: "engage",
          isNumber: true
        },
        {
          text: this.$t("header.executed"),
          align: "end",
          sortable: false,
          value: "passee",
          isNumber: true
        }
      ]
    };
  },
  mixins: [UserSessionData],
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getCharge()
          .then(response => {
            this.list = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
