<template>
  <v-container fluid fill-height v-if="isLgt">
    <v-row>
      <base-reservation-table
        v-if="!isCO"
        :items="endedResaWithouCheckout"
        label="Séjours Terminés SANS CheckOut"
        :loading="loading"
        @clore="checkoutReservations"
      ></base-reservation-table>
    </v-row>
    <v-row>
      <v-col lg="12" class="px-0 px-md-3">
        <v-card
          cols="12"
          class="px-0 px-md-0"
          style="max-height: 640px; overflow: auto"
        >
          <v-card-title class="headline display-1">
            {{
              ongoingReservations.length > 1
                ? ongoingReservations.length + " séjours "
                : ongoingReservations.length + " séjour "
            }}
            en cours
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              clearable
              append-icon="search"
              :label="$t('tableStatus.searchAll')"
              single-line
              hide-details
              class="mt-n2"
            >
            </v-text-field>
          </v-card-title>
          <v-card-subtitle>
            {{
              selectedReservations.length > 1
                ? selectedReservations.length + " séjours sélectionnés"
                : selectedReservations.length + " séjour sélectionné"
            }}
          </v-card-subtitle>
          <v-card-text class="px-2 px-md-4 mb-6">
            <v-data-table
              v-model="selectedReservations"
              :items="ongoingReservations"
              :loading="loading"
              :search="searched"
              :headers="headers"
              item-key="id"
              show-select
              class="elevation-1 table is_bordered"
              sort-by="begin"
              sort-desc
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:header.data-table-select="{ props, on }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-avatar class="white ml-1" size="32">
                        <v-icon color="primary">menu</v-icon>
                      </v-avatar>
                    </v-btn>
                  </template>
                  <v-list class="pa-0" light>
                    <v-list-item
                      style="background-color: rgb(240, 240, 240);"
                      @click="unselectAll()"
                      :disabled="selectedReservations.length === 0"
                    >
                      Effacer sélection
                    </v-list-item>
                    <v-list-item
                      @click="checkoutReservations(selectedReservations)"
                      :disabled="selectedReservations.length === 0"
                    >
                      Clore
                    </v-list-item>
                    <v-list-item
                      style="background-color: rgb(240, 240, 240);"
                      @click="changeEmail(selectedReservations)"
                      :disabled="selectedReservations.length != 1"
                    >
                      Changer l'email de la resa
                    </v-list-item>
                    <v-list-item
                      @click="changeEndDate(selectedReservations)"
                      :disabled="selectedReservations.length != 1"
                    >
                      Changer la date de fin de séjour
                    </v-list-item>
                    <v-list-item
                      style="background-color: rgb(240, 240, 240);"
                      @click="forceIn(selectedReservations)"
                      :disabled="selectedReservations.length === 0"
                    >
                      Forcer statut à IN
                    </v-list-item>
                    <v-list-item
                      @click="resendInfo(selectedReservations)"
                      :disabled="selectedReservations.length === 0"
                    >
                      Renvoyer le mail d'arrivée
                    </v-list-item>
                    <v-list-item
                      style="background-color: rgb(240, 240, 240);"
                      @click="downloadFile(selectedReservations)"
                      :disabled="selectedReservations.length === 0"
                    >
                      Télécharger les EDL
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.lgtStatusText="{ item }">
                <v-chip
                  x-small
                  :color="
                    item.lgtStatusText === 'IN'
                      ? 'primary'
                      : item.lgtStatusText === 'R2R'
                      ? 'green'
                      : item.lgtStatusText == 'OUT'
                      ? 'orange'
                      : 'grey'
                  "
                  text-color="white"
                >
                  {{ item.lgtStatusText }}</v-chip
                >
              </template>
              <template v-slot:item.lgtName="{ item }">
                <v-btn
                  class="elevation-0"
                  color="rgba(0, 0, 0, 0)"
                  small
                  @click="clicked(item)"
                >
                  {{ item.lgtName }}
                </v-btn>
              </template>
              <template v-slot:item.begin="{ item }">
                {{ formatDate(item.begin) }}
              </template>
              <template v-slot:item.end="{ item }">
                {{ formatDate(item.end) }}
              </template>
              <template v-slot:item.menageSat="{ item }">
                <template v-if="item.menageSat < 1">
                  <v-tooltip top color="#333F48">
                    <template v-slot:activator="{ on }">
                      <v-icon large color="white" class="pa-1" v-on="on">
                        {{
                          hideElt(item.menageSat) + satClient(item.menageSat)
                        }}
                      </v-icon>
                    </template>
                    <span class="pa-6" v-html="deltaMenage(item)"></span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-icon large color="white" class="pa-1" v-on="on">
                    {{ hideElt(item.menageSat) + satClient(item.menageSat) }}
                  </v-icon>
                </template>
              </template>
              <template v-slot:item.edlSat="{ item }">
                <template v-if="item.edlSat < 1">
                  <v-tooltip top color="#333F48">
                    <template v-slot:activator="{ on }">
                      {{ item.edlSat === null ? sat2Client(item.edlSat) : "" }}
                      <v-icon large color="white" class="pa-1" v-on="on">
                        {{
                          item.edlSat != null
                            ? hideElt(item.edlSat) + sat2Client(item.edlSat)
                            : ""
                        }}
                      </v-icon>
                    </template>
                    <span class="pa-6" v-html="deltaEdl(item)"></span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ item.edlSat === null ? sat2Client(item.edlSat) : "" }}
                  <v-icon large color="white" class="pa-1" v-on="on">
                    {{
                      item.edlSat != null
                        ? hideElt(item.edlSat) + sat2Client(item.edlSat)
                        : ""
                    }}
                  </v-icon>
                </template>
              </template>
              <template v-slot:item.docInId="{ item }">
                <v-btn
                  color="primary"
                  small
                  v-if="item.docInId"
                  @click="openFile(item.docInId)"
                  ><v-icon>mdi-file-eye</v-icon></v-btn
                >
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"> </v-pagination>
            </div>
          </v-card-text>
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-6" width="100%">
      <v-card-title class="headline display-1">
        <v-col md="3">
          Séjours écoulés
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="search"
            clearable
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-col>
        <v-col md="2">
          <div align="right" class="subtitle-2 mt-1">
            Selectionner par date de
          </div>
        </v-col>
        <v-col md="1">
          <div>
            <toggle-button
              :value="true"
              :width="60"
              :color="{
                checked: 'var(--v-primary-base)',
                unchecked: 'var(--v-primary-base)'
              }"
              :labels="{ checked: 'Début', unchecked: 'Fin' }"
              @change="changeChoix()"
            />
          </div>
        </v-col>
        <v-col md="3">
          <doc-filter
            :dateRange="dateRange"
            @updatedranges="dateRange = $event"
          ></doc-filter>
        </v-col>
      </v-card-title>
      <v-card-subtitle v-if="!this.searched" class="mt-n5">
        {{
          oldReservations.length > 1
            ? oldReservations.length + " séjours (filtrés) "
            : oldReservations.length + " séjour (filtré) "
        }}
        /
        {{
          selectedOldReservations.length > 1
            ? selectedOldReservations.length + " séjours sélectionnés"
            : selectedOldReservations.length + " séjour sélectionné"
        }}
      </v-card-subtitle>
      <v-card-subtitle v-else class="mt-n5">
        {{
          selectedOldReservations.length > 1
            ? selectedOldReservations.length + " séjours sélectionnés"
            : selectedOldReservations.length + " séjour sélectionné"
        }}
      </v-card-subtitle>
      <v-row class="mt-n8">
        <v-col lg="12" class="px-0 px-md-3">
          <v-card
            cols="12"
            flat
            class="px-0 px-md-0"
            style="max-height: 640px; overflow: auto"
          >
            <v-card-text class="px-2 px-md-4 mb-6">
              <v-data-table
                v-model="selectedOldReservations"
                :items="oldReservations"
                :loading="loading"
                :search="searched"
                :headers="headersOld"
                item-key="id"
                show-select
                class="elevation-1 table is_bordered"
                sort-by="begin"
                sort-desc
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template v-slot:header.data-table-select="{ props, on }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-avatar class="white ml-1" size="32">
                          <v-icon color="primary">menu</v-icon>
                        </v-avatar>
                      </v-btn>
                    </template>
                    <v-list class="pa-0" light>
                      <v-list-item
                        style="background-color: rgb(240, 240, 240);"
                        @click="unselectAll()"
                        :disabled="selectedOldReservations.length === 0"
                      >
                        Effacer sélection
                      </v-list-item>
                      <v-list-item
                        @click="downloadFile(selectedOldReservations)"
                        :disabled="selectedOldReservations.length === 0"
                      >
                        Télécharger les documents
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:item.begin="{ item }">
                  {{ formatDate(item.begin) }}
                </template>
                <template v-slot:item.end="{ item }">
                  {{ formatDate(item.end) }}
                </template>
                <template v-slot:item.menageSat="{ item }">
                  <v-icon large color="white" class="pa-1">
                    {{ hideElt(item.menageSat) + satClient(item.menageSat) }}
                  </v-icon>
                </template>
                <template v-slot:item.edlSat="{ item }">
                  {{ item.edlSat === null ? sat2Client(item.edlSat) : "" }}
                  <v-icon large color="white" class="pa-1">
                    {{
                      item.edlSat != null
                        ? hideElt(item.edlSat) + sat2Client(item.edlSat)
                        : ""
                    }}
                  </v-icon>
                </template>
                <template v-slot:item.endSat="{ item }">
                  {{ item.endSat ? item.endSat + "/5" : "?" }}
                </template>
                <template v-slot:item.docInId="{ item }">
                  <v-btn
                    color="primary"
                    small
                    class="mx-n3"
                    v-if="item.docInId"
                    @click="openFile(item.docInId)"
                    ><v-icon>mdi-file-eye</v-icon></v-btn
                  >
                </template>
                <template v-slot:item.docOutId="{ item }">
                  <v-btn
                    color="secondary"
                    small
                    class="mx-n3"
                    v-if="item.docOutId"
                    @click="openFile(item.docOutId)"
                    ><v-icon>mdi-file-eye</v-icon></v-btn
                  >
                </template>
                <template v-slot:item.docContradictoireId="{ item }">
                  <v-btn
                    color="secondary"
                    small
                    class="mx-n3"
                    v-if="item.docContradictoireId"
                    @click="openFile(item.docContradictoireId)"
                    ><v-icon>mdi-file-eye</v-icon></v-btn
                  >
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount">
                </v-pagination>
              </div>
            </v-card-text>
            <v-overlay absolute :value="loading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-6">
      <base-reservation-table
        v-if="isLoc"
        :items="comingResa"
        label="Séjours à venir"
        :loading="loading"
      ></base-reservation-table>
    </v-row>
    <v-snackbar
      v-model="error.snackbar"
      :timeout="3000"
      v-for="(error, index) in errors"
      :key="index"
      absolute
      right
      top
      tile
      multi-line
      color="red accent-2"
    >
      <v-row>
        <v-col col="12">
          <h2>{{ error.title }}</h2>
        </v-col>
        <v-col cols="12">
          <p v-html="error.msg"></p>
        </v-col>
      </v-row>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="errors.splice(index, 1)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
  <v-container fluid fill-height v-else>
    Onglet uniquement disponible pour les logements.
  </v-container>
</template>
<script>
import OBJECT_API from "../../api/object";
import TICKET_API from "../../api/ticket";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import BaseReservationTable from "./BaseReservationTable.vue";
import DocFilter from "./DocFilter.vue";
import USER_API from "../../api/user";
import { hideElt } from "../../utils";
import Swal from "sweetalert2";
import { mySwalClass } from "../../utils/alert";
import { getReservationState } from "../../utils/entity";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "OngletResa",
  components: {
    BaseReservationTable,
    DocFilter,
    ToggleButton
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      documents: [],
      documentsList: [],
      lgtList: "",
      loading: false,
      loadingDoc: false,
      reservations: [],
      errors: [],
      refreshKey: 0,
      dateRange: {
        start: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")
      },
      singleSelect: false,
      search: "",
      slider: true,
      choix: "start",
      selectedReservations: [],
      selectedOldReservations: [],
      headers: [
        {
          text: "Logement",
          align: "start",
          sortable: true,
          value: "lgtName"
        },
        {
          text: "Lgt ?",
          align: "center",
          sortable: true,
          value: "lgtStatusText"
        },
        { text: "Début", value: "begin" },
        { text: "Fin", value: "end" },
        { text: "Téléphone", value: "tel" },
        { text: "Email", value: "email" },
        {
          text: "Ménage",
          align: "center",
          sortable: true,
          value: "menageSat"
        },
        {
          text: "EDL",
          align: "center",
          sortable: true,
          value: "edlSat"
        },
        {
          text: "IN",
          align: "center",
          sortable: false,
          value: "docInId",
          class: "t-row"
        }
      ],
      headersOld: [
        {
          text: "Logement",
          align: "start",
          sortable: true,
          value: "lgtName"
        },
        { text: "Début", value: "begin" },
        { text: "Fin", value: "end" },
        { text: "Téléphone", value: "tel" },
        { text: "Email", value: "email" },
        {
          text: "Ménage",
          align: "center",
          sortable: true,
          value: "menageSat"
        },
        {
          text: "EDL",
          align: "center",
          sortable: true,
          value: "edlSat"
        },
        {
          text: "sat",
          align: "center",
          sortable: true,
          value: "endSat"
        },
        {
          text: "IN",
          align: "center",
          sortable: false,
          value: "docInId",
          class: "t-row"
        },
        {
          text: "OUT",
          align: "center",
          sortable: false,
          value: "docOutId",
          class: "t-row"
        },
        {
          text: "CONTR.",
          align: "center",
          sortable: false,
          value: "docContradictoireId",
          class: "t-row"
        }
      ],
      page: 1,
      pageCount: 0
    };
  },
  computed: {
    ...mapGetters([
      "getActiveEntity",
      "getEntity",
      "getSessionToken",
      "getUserProfiles"
    ]),
    searched() {
      this.unselectAll();
      return this.search;
    },
    selectedEntityID() {
      return this.getActiveEntity.id;
    },
    ongoingReservations() {
      //afficher les resa
      /*let end2 = moment.tz(this.dateRange.end, "Europe/Paris").add(1, "days");
      let data = [];
      this.reservations
        .filter(
          resa =>
            resa.checkoutOk === null &&
            moment
              .tz(resa.begin, "Europe/Paris")
              .isAfter(this.dateRange.start, "Europe/Paris") &&
            moment.tz(resa.begin, "Europe/Paris").isBefore(end2, "Europe/Paris")
        )
        //.forEach(el => data.push([el.email,el.begin,el.lgtName]))
        .forEach(el => data.push(el.email));
      console.log(data);*/
      return this.reservations.filter(resa => resa.checkoutOk === null);
    },
    oldReservations() {
      if (this.choix == "start") return this.oldReservationsbyStart;
      else return this.oldReservationsbyEnd;
    },
    oldReservationsbyStart() {
      let end2 = moment.tz(this.dateRange.end, "Europe/Paris").add(1, "days");
      //afficher les resa
      /*
      let data = [];
      this.reservations
        .filter(
          resa =>
            moment
              .tz(resa.begin, "Europe/Paris")
              .isAfter(this.dateRange.start, "Europe/Paris") &&
            moment
              .tz(resa.begin, "Europe/Paris")
              .isBefore(end2, "Europe/Paris") &&
            moment
              .tz(resa.end, "Europe/Paris")
              .isBefore(moment.tz("Europe/Paris"))
        )
        //.forEach(el => data.push([el.email,el.begin,el.lgtName]))
        .forEach(el => data.push(el.email));
      console.log(data);*/
      return this.reservations.filter(
        resa =>
          moment
            .tz(resa.begin, "Europe/Paris")
            .isAfter(this.dateRange.start, "Europe/Paris") &&
          moment
            .tz(resa.begin, "Europe/Paris")
            .isBefore(end2, "Europe/Paris") &&
          moment
            .tz(resa.end, "Europe/Paris")
            .isBefore(moment.tz("Europe/Paris"))
      );
    },
    oldReservationsbyEnd() {
      let end2 = moment.tz(this.dateRange.end, "Europe/Paris").add(1, "days");
      return this.reservations.filter(
        resa =>
          moment
            .tz(resa.end, "Europe/Paris")
            .isAfter(this.dateRange.start, "Europe/Paris") &&
          moment.tz(resa.end, "Europe/Paris").isBefore(end2, "Europe/Paris") &&
          moment
            .tz(resa.end, "Europe/Paris")
            .isBefore(moment.tz("Europe/Paris"))
      );
    },
    comingResa() {
      return this.reservations.filter(
        resa =>
          moment
            .tz(resa.begin, "Europe/Paris")
            .isAfter(moment.tz("Europe/Paris")) //&& resa.comment.state
      );
    },
    endedResaWithouCheckout() {
      return this.reservations.filter(
        resa =>
          moment
            .tz(resa.end, "Europe/Paris")
            .isBefore(moment.tz("Europe/Paris")) && resa.checkoutOk == 0
      );
    },
    isCO() {
      try {
        let conf = JSON.parse(
          this.getEntity.filter(
            el => el.id == this.getActiveEntity.entities_id
          )[0].website
        );
        return conf.CO && conf.CO != 0;
      } catch {
        return false;
      }
    },
    isLoc() {
      return this.getUserProfiles.includes(36);
    },
    isLgt() {
      if (this.$route.query.tab) return true;
      if (!this.getActiveEntity.state) return true;
      return this.getActiveEntity.state.includes("Logement");
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 500;
    }
  },
  methods: {
    deltaMenage(item) {
      if (item.menageSat != 1) {
        let delta = [];
        item.edlecart.forEach(i => {
          if (
            i.item.split(" /")[0].toLowerCase() == "ménage" ||
            i.item.split(" /")[0].toLowerCase() == "menage"
          )
            delta.push(i.item.split("/ ")[1]);
        });
        const deltaText = "- " + delta.join("<br/>- ");
        const titre = "<b>Ecarts ménage sur " + item.lgtName + "</b><br/>";
        return titre + deltaText;
      }
    },
    deltaEdl(item) {
      if (item.edlSat != 1) {
        let delta = [];
        item.edlecart.forEach(i => {
          if (
            i.item.split(" /")[0].toLowerCase() != "ménage" &&
            i.item.split(" /")[0].toLowerCase() != "menage"
          )
            delta.push(i.item);
        });
        const deltaText = "- " + delta.join("<br/>- ");
        const titre = "<b>Ecarts EDL sur " + item.lgtName + "</b><br/>";
        return titre + deltaText;
      }
    },
    changeEmail(selectedReservations) {
      let resaContent = selectedReservations[0];
      let formerEmail = resaContent.email;
      Swal.fire({
        title: "Nouveau mail",
        input: "email",
        inputValue: `${formerEmail}`,
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          resaContent.email = result.value;
          try {
            OBJECT_API.reservationUpdateMail(
              this.getSessionToken,
              resaContent.id,
              result.value
            );
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
          this.unselectAll();
          Swal.fire({
            title: "OK",
            showConfirmButton: false,
            customClass: mySwalClass,
            timerProgressBar: true,
            icon: "success",
            timer: 1500
          });
        }
      });
    },
    async changeEndDate(selectedReservations) {
      let resaContent = selectedReservations[0];
      let formerEnd = resaContent.end.substring(0, 10);
      const today = new Date().toISOString();
      const minDate = today.split("T")[0];
      const { value: formValues } = await Swal.fire({
        title: "Date de fin de séjour",
        html: `
          <input id="swal-input1" class="swal2-input" value="${formerEnd}" type="date" min="${minDate}">
        `,
        focusConfirm: false,
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        customClass: mySwalClass,
        preConfirm: () => {
          return [document.getElementById("swal-input1").value];
        }
      });

      if (formValues) {
        try {
          resaContent.end = formValues[0];
          OBJECT_API.reservationUpdateDate(
            this.getSessionToken,
            selectedReservations[0].id,
            formValues[0]
          );
        } catch ({ response }) {
          this.errors.push({
            title: "Erreur ",
            msg: response.data,
            snackbar: true
          });
        }
        this.unselectAll();
        Swal.fire({
          title: "OK",
          showConfirmButton: false,
          customClass: mySwalClass,
          timerProgressBar: true,
          icon: "success",
          timer: 1500
        });
      }
    },
    async forceIn(selectedReservations) {
      for await (const elt of selectedReservations) {
        const entN4 = this.getActiveEntity.entities_id;
        try {
          OBJECT_API.changeR2R(
            this.getSessionToken,
            "Logement",
            elt.idLgt,
            3,
            elt.lgtName,
            "manuellement",
            entN4
          );
        } catch ({ response }) {
          this.errors.push({
            title: "Attention ",
            msg: response.data,
            snackbar: true
          });
        }
      }
      setTimeout(() => {
        this.errors = [];
        this.$router.go(0);
      }, 5000);
    },
    resendInfo(selectedReservations) {
      Swal.fire({
        title: "Êtes vous sûrs?",
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: "warning",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        customClass: mySwalClass
      }).then(result => {
        if (result.isConfirmed) {
          let fromName = "";
          try {
            let conf = JSON.parse(
              this.getEntity.filter(
                el => el.id == this.getActiveEntity.entities_id
              )[0].website
            );
            fromName = conf.mail.from_name;
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
          let resas = [];
          selectedReservations.forEach(elt => {
            resas.push(elt.id);
          });
          try {
            OBJECT_API.resendInfo(this.getSessionToken, resas, fromName);
          } catch ({ response }) {
            this.errors.push({
              title: "Erreur ",
              msg: response.data,
              snackbar: true
            });
          }
          this.unselectAll();
        }
      });
    },
    unselectAll() {
      this.selectedReservations = [];
      this.selectedOldReservations = [];
    },
    async getReservationList2() {
      this.loading = true;
      const { data } = await OBJECT_API.getSejour(this.getSessionToken);
      this.reservations = data;
      this.reservations.forEach(i => {
        i.lgtStatusText = getReservationState(i.lgtStatus);
      });
      this.loading = false;
    },
    async checkoutReservations(selectedReservations) {
      this.loading = true;
      for await (const el of selectedReservations) {
        const entN5 = this.$route.params.id;
        const entN4 = this.getActiveEntity.entities_id;
        let conf = JSON.parse(
          this.getEntity.filter(el => el.id == entN4)[0].website
        );
        let statutOnClose = conf.on_close ? conf.on_close : "R2R"; // si pas de config on met R2R
        let lgtName = el.lgtName;
        try {
          OBJECT_API.reservationCheckoutR2R(
            this.getSessionToken,
            el.reservationitems_id,
            statutOnClose,
            lgtName,
            entN4,
            entN5,
            el.id
          );
        } catch ({ response }) {
          const name = el.lgtName ? el.lgtName : "";
          this.errors.push({
            title: "Ligne " + el.id + ": " + name,
            msg: response.data,
            snackbar: true
          });
        }
      }
      this.getReservationList2();
      setTimeout(() => {
        this.errors = [];
        this.$router.go(0);
      }, 5000);
    },
    clearError(index) {
      this.errors.splice(index, 1);
    },
    clicked(row) {
      this.search = row.lgtName;
    },
    hideElt,
    formatDate(date) {
      return moment(date).format("ddd DD/MM/YY");
    },
    satClient(sat) {
      return sat === null
        ? "mdi-help orange"
        : sat > 0
        ? "mdi-emoticon-happy green"
        : sat < 0
        ? "mdi-emoticon-sad red"
        : "mdi-emoticon-neutral orange";
    },
    sat2Client(sat2) {
      return sat2 === null
        ? "non validé"
        : sat2 > 0
        ? "mdi-emoticon-happy green"
        : sat2 < 0
        ? "mdi-emoticon-sad red"
        : "mdi-emoticon-neutral orange";
    },
    getImage(docId) {
      const images = this.documents.filter(el => el.documents_id == docId);
      return images && images.length > 0 ? images[0] : null;
    },
    async loadDocumentsImages() {
      try {
        const { data } = await TICKET_API.getDocumentsItems(9);
        this.documents = data;
      } catch {
        this.documents = [];
      }
    },
    openFile(docId) {
      USER_API.getDocument(docId).then(async response => {
        USER_API.openDocument(response.data);
      });
    },
    async downloadFile(selectedReservations) {
      for (const resa of selectedReservations) {
        let docs = [];
        docs.in = resa.docInId;
        docs.out = resa.docOutId;
        docs.ag = resa.docContradictoireId;

        if (docs.in) {
          USER_API.getDocument(docs.in).then(async response => {
            USER_API.downloadDocument(response.data);
          });
        }
        if (docs.out) {
          USER_API.getDocument(docs.out).then(async response => {
            USER_API.downloadDocument(response.data);
          });
        }
        if (docs.ag) {
          USER_API.getDocument(docs.ag).then(async response => {
            USER_API.downloadDocument(response.data);
          });
        }
        this.unselectAll();
      }
    },
    changeChoix() {
      this.slider = !this.slider;
      this.slider == true ? (this.choix = "start") : (this.choix = "");
    }
  },
  async mounted() {
    this.getReservationList2();
  },
  watch: {
    selectedEntityID() {
      this.getReservationList2();
    },
    $route() {
      this.getReservationList2();
    },
    active() {
      this.getReservationList2();
    }
  }
};
</script>
<style></style>
