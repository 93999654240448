<template>
  <v-container fluid fill-height>
    <calendar :key="$route.params.id"></calendar>
  </v-container>
</template>
<script>
//import SiteLife from "./SiteLife";
import Calendar from "../Calendar";

export default {
  name: "OngletSiteLife",
  components: { Calendar }
};
</script>
