<template>
  <v-col lg="12" class="px-0 px-md-3">
    <v-card cols="12" class="px-0 px-md-0">
      <v-card-title class="headline display-1">
        {{ label }}
      </v-card-title>
      <v-card-text class="px-2 px-md-4 mb-6">
        <v-data-table
          v-model="selectedReservations2"
          :headers="headers"
          :items="items"
          item-key="id"
          :loading="loading"
          show-select
          class="elevation-1 table is_bordered"
          sort-by="date"
          sort-desc
          :group-desc="false"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:header.data-table-select="{ props, on }">
            <v-btn
              elevation="3"
              x-small
              @click="$emit('clore', selectedReservations2)"
              :disabled="selectedReservations2.length === 0"
            >
              Clore
            </v-btn>
          </template>
          <template v-slot:group.header="{ toggle, group, isOpen }">
            <td colspan="4" class="pl-0">
              <v-btn @click="toggle()" icon>
                <v-icon
                  >mdi-{{
                    isOpen
                      ? "arrow-up-drop-circle-outline"
                      : "arrow-down-drop-circle-outline"
                  }}</v-icon
                >
              </v-btn>
              <span class="primary--text">{{ group }}</span>
            </td>
          </template>
          <template v-slot:item.begin="{ item }">
            {{ formatDate(item.begin) }}
          </template>
          <template v-slot:item.end="{ item }">
            {{ formatDate(item.end) }}
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-col>
</template>
<script>
import moment from "moment";

export default {
  name: "BaseReservationTable",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      singleSelect: false,
      selectedReservations2: [],
      headers: [
        {
          text: "Logement",
          align: "start",
          sortable: false,
          value: "lgtName"
        },
        { text: "Début", value: "begin" },
        { text: "Fin", value: "end" },
        { text: "Téléphone", value: "tel" },
        { text: "Email", value: "email" }
      ],
      page: 1,
      pageCount: 0
    };
  },
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 30;
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("ddd DD MMMM YY");
    }
  }
};
</script>
