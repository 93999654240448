<template>
  <v-row justify="center">
    <v-alert v-show="alert" :type="alertType" cols="5">
      {{ alertMessage }}
    </v-alert>
    <v-card-title>
      <v-btn
        class="mb-mt-my-16"
        mb="6"
        v-if="!isMobile"
        color="primary"
        :disabled="selectedDocs.length === 0"
        @click="deleteSelectedFiles"
        absolute
        left
      >
        Supprimer les docs
      </v-btn>
    </v-card-title>
    <v-col v-if="isMobile" cols="12">
      <v-list style="max-height: 640px; overflow: auto">
        <v-list-item-group color="primary">
          <template v-if="loadingDoc">
            <v-list-item v-for="(item, i) in 2" :key="i">
              <v-list-item-content>
                <v-skeleton-loader
                  ref="skeleton"
                  type="list-item-avatar-two-line"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item v-for="item in docList" :key="item.id">
              <v-list-item-avatar>
                <v-icon large color="primary">
                  {{ documentIcon(item.mime) }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  @click="openFile(item)"
                  title="Ouvrir le document dans une nouvelle fenêtre"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.documentcategories_id !== 0"
                  v-text="item.documentcategories_id"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-text="
                    moment(item.date_creation, 'YYYY-MM-Do HH:mm ss').format(
                      ' Do MMM YYYY, HH:mm'
                    )
                  "
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="downloadFile(item)"
                  :title="$t('assetDetails.text3')"
                >
                  <v-icon dark>mdi-cloud-download-outline</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col v-else cols="12">
      <v-list style="max-height: 640px; overflow: auto">
        <v-list-item-group
          color="primary"
          v-model="selectedDocs"
          multiple
          active-class=""
        >
          <template v-if="loadingDoc">
            <v-list-item v-for="(item, i) in 2" :key="i">
              <v-list-item-content>
                <v-skeleton-loader
                  ref="skeleton"
                  type="list-item-avatar-two-line"
                  class="mx-auto"
                ></v-skeleton-loader>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item v-for="item in docList" :key="item.id">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-avatar>
                  <v-icon large color="primary">
                    {{ documentIcon(item.mime) }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="item.documentcategories_id !== 0"
                    v-text="item.documentcategories_id"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="
                      moment(item.date_creation, 'YYYY-MM-Do HH:mm ss').format(
                        ' Do MMM YYYY, HH:mm'
                      )
                    "
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="openFile(item)"
                    :title="$t('assetDetails.text2')"
                  >
                    <v-icon dark>mdi-file-eye</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="downloadFile(item)"
                    :title="$t('assetDetails.text3')"
                  >
                    <v-icon dark>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "../../plugins/moment";
import USER_API from "../../api/user";

export default {
  props: {
    loadingDoc: {
      type: Boolean,
      deafault: false
    },
    docList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      selectedDocs: [],
      alert: false,
      alertMessage: "",
      alertType: "success"
    };
  },
  computed: {
    ...mapGetters(["getActiveEntity"]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    moment,
    documentIcon(mimeType) {
      if (mimeType === "image/jpeg") {
        return "mdi-file-image";
      } else if (mimeType === "application/pdf") {
        return "mdi-file-document";
      } else {
        return "mdi-file";
      }
    },
    downloadFile(item) {
      USER_API.downloadDocument(item);
    },
    openFile(item) {
      USER_API.openDocument(item);
    },
    async deleteSelectedFiles() {
      for (let el of this.selectedDocs) {
        let docLevel = this.docList[el].entities_id.split(">").length;
        if (docLevel === 4) {
          await USER_API.setActiveEntity(this.getActiveEntity.entities_id);
          await USER_API.updateTouristDoc(this.docList[el])
            .then(() => {
              this.alert = true;
              this.alertMessage = "Suppression effectuée";
              this.alertType = "success";
              setTimeout(() => {
                this.alert = false;
              }, 6000);
            })
            .catch(() => {
              this.alert = true;
              this.alertMessage =
                "Pour supprimer des fichiers communs, demandez à votre administrateur";
              this.alertType = "error";
              setTimeout(() => {
                this.alert = false;
              }, 2000);
            });
        } else {
          await USER_API.updateTouristDoc(this.docList[el])
            .then(() => {
              this.alert = true;
              this.alertMessage = "Suppression effectuée";
              this.alertType = "success";
              setTimeout(() => {
                this.alert = false;
              }, 6000);
            })
            .catch(() => {
              this.alert = true;
              this.alertMessage =
                "Pour supprimer des fichiers communs, demandez à votre administrateur";
              this.alertType = "error";
              setTimeout(() => {
                this.alert = false;
              }, 2000);
            });
        }
      }
      setTimeout(() => {
        this.$router.go(0);
      }, 2000);
    }
  }
};
</script>
