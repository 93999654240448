<template>
  <v-col cols="12">
    <v-card class="mt-6" flat min-width="300px">
      <v-card v-if="isCartoMap" flat width="98%" class="mb-6">
        <map-radius :list="locationList"></map-radius>
      </v-card>
      <v-card-title class="headline display-1">
        {{ $t("tableTitle.list_user") }}
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          fab
          elevation="1"
          small
          :href="exportLink"
          dark
          color="white"
          target="_blank"
          title="Exporter en csv"
        >
          <v-icon color="primary">mdi-file-delimited</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('tableStatus.searchAll')"
          single-line
          hide-details
          class="mt-n2"
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="name"
          class="table is_bordered"
          :loading="loading"
          sort-by="date_creation"
          sort-desc
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:item="{ item }">
            <c-row
              :item="item"
              :headers="headers"
              rtl
              ignore-null-val
            ></c-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import { UserSessionData } from "../../mixins/userData";
import MapRadius from "../../components/MapRadius";
import USER_API from "../../api/user";
import { mapGetters } from "vuex";
import { Decoder } from "../../mixins/decodeContent";
import { PORTAL_CONFIG } from "../../config";

export default {
  name: "Info",
  components: { MapRadius },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      pageCount: 0,
      nbgps: 1,
      search: "",
      isCartoMap: true,
      locationList: [],
      rayon: [],
      loading: false,
      headers: [
        {
          text: this.$t("header.name"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: "Langue",
          align: "left",
          sortable: true,
          value: "comment"
        },
        {
          text: "Documents",
          align: "right",
          sortable: false,
          value: "other"
        },
        {
          text: "Date de la demande",
          align: "right",
          sortable: true,
          value: "date_creation"
        },
        {
          text: "email",
          align: "right",
          sortable: true,
          value: "usermail"
        }
      ]
    };
  },
  mixins: [UserSessionData, Decoder],
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "getAllITILFollowups"
    ]),
    exportLink() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/info_visiteur.php?getiv&user=${this.getUserToken}`;
    }
  },
  methods: {
    loadData() {
      fetch(`
        ${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/where_user.php?user=${this.getUserToken}
        `)
        .then(response => {
          return response.json();
        })
        .then(result => {
          this.locationList = result;
        });
      if (this.display && this.isAuth) {
        this.loading = true;
        USER_API.getEquipment("User")
          .then(response => {
            this.list = response.data;
            const size = this.list.length;
            //this.locationList = [];
            //let nbgps = 0;
            //let clientName = this.getActiveEntity.completename
            //  .split(">")[1]
            //  .trim();
            if (size > 0) {
              //pour mettre les points sur la carte
              /*for (let i = 0; i < size; i++) {
                if (this.list[i].teamid && this.list[i].teamid !== "/") {
                  this.rayon[i] = 300;
                  nbgps = nbgps + 1;
                  this.locationList.push({
                    name:
                      isNaN(this.list[i].name) === true
                        ? this.list[i].name.toUpperCase()
                        : this.list[i].name,
                    /* si souci avec les markers, il faudrait peut etre utiliser cela
                    operationnel:
                      this.list[i].operationnel !== undefined
                        ? this.list[i].operationnel
                        : "NA",
                    lat: this.list[i].teamid.split("/")[0],
                    lng: this.list[i].teamid.split("/")[1],
                    radius: this.rayon[i]
                  });
                }
              }*/
              //pour écrire le tableau
              for (let i = 0; i < size; i++) {
                if (this.list[i].other) {
                  this.list[i].other = JSON.parse(this.list[i].other);
                }
                if (isNaN(this.list[i].name) === true) {
                  this.list[i].name = this.list[i].name.toUpperCase();
                }
              }
            }
            if (this.nbgps === 0) {
              this.isCartoMap = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  watch: {
    display() {
      this.loadData();
    }
  }
};
</script>
