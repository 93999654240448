<template>
  <v-row no-gutters class="pa-2">
    <v-col cols="9">
      <v-card-title class="headline display-1">
        {{ $t("index.documents") }}
      </v-card-title>
    </v-col>
    <v-col cols="3" md="6" class="mt-3 pr-2">
      <v-row justify="center">
        <v-dialog v-model="docFilterDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $t("tableStatus.filter") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("tableStatus.filter") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      :label="$t('tableStatus.by_name')"
                      :items="docNames"
                      clearable
                      v-model="names"
                      multiple
                      chips
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      :label="$t('tableStatus.by_category')"
                      :items="docCategories"
                      clearable
                      v-model="categories"
                      multiple
                      chips
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-daterange
                      v-model="ranges"
                      display-format="dd-MM-yyyy"
                      no-title
                      :menu-props="menuProps"
                      :presets="presets"
                      :start-label="$t('labels.start_date')"
                      :end-label="$t('labels.end_date')"
                      :separator-label="$t('labels.to')"
                      :locale="$root.$i18n.locale"
                    ></v-daterange>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="docFilterDialog = false"
                >OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { DocFilterMixin } from "./filter.mixins";

export default {
  mixins: [DocFilterMixin],
  data() {
    return {
      docFilterDialog: false
    };
  }
};
</script>
