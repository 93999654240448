export const treeFunction = {
  getEntitiesByLevel(list, level) {
    return list ? list.filter(entity => entity.level === level) : [];
  },
  getEntityTree(entity) {
    return {
      id: entity.id,
      name: entity.name,
      level: entity.level,
      children: this.getChildren(entity)
    };
  },
  getChildren(entity) {
    return this.getEntitiesByLevel(entity.children, entity.level + 1)
      .filter(ent => ent.entities_id === entity.id)
      .map(child => this.getEntityTree(child));
  }
};

export function getReverseReservationState(stateID) {
  switch (stateID) {
    case "R2R":
      return 2;
    case "IN":
      return 3;
    case "OUT":
      return 4;
    case "CLOSE":
      return 8;
    default:
      return "Non defini";
  }
}

export function getReservationState(stateID) {
  const id = parseInt(stateID);
  switch (id) {
    case 2:
      return "R2R";
    case 3:
      return "IN";
    case 4:
      return "OUT";
    case 8:
      return "CLOSE";
    default:
      return "NA";
  }
}
