<template>
  <v-row class="fill-height" id="calendar-view">
    <v-col cols="12">
      <v-sheet height="64" class="mt-n3" color="transparent" id="toolbar">
        <v-toolbar v-if="isMobile" flat width="100%">
          <v-btn-toggle v-model="toggle_type" color="primary">
            <v-btn small>
              M/J
            </v-btn>
          </v-btn-toggle>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small color="primary">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small color="primary">mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title
            color="black"
            class="d-flex align-baseline small"
            v-html="smalltitle"
          >
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar v-else flat width="100%">
          <v-btn-toggle v-model="toggle_type" color="primary">
            <v-btn x-large>
              {{ $t("calendar.day") }}
            </v-btn>
            <v-btn x-large>
              {{ $t("calendar.week") }}
            </v-btn>
            <v-btn x-large>
              {{ $t("calendar.month") }}
            </v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
          <v-toolbar-title
            color="black"
            class="d-flex align-baseline"
            v-html="title"
          >
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small color="primary">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn outlined color="grey darken-2" @click="setToday">
            {{ $t("calendar.today") }}
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small color="primary">mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-sheet>
      <v-sheet v-if="isMobilePaysage">
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekdaymob"
          :interval-height="42"
          :event-height="25"
          color="primary"
          :locale="locale"
          :type="types[toggle_type]"
          :interval-style="styleInterval"
          :event-name="eventName"
          :events="allEvents"
          :event-color="getEventColor"
          :short-weekdays="true"
          @change="updateRange"
          :first-interval="7"
          :interval-count="15"
          :interval-format="intervalFormat"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewWeek"
          @touchend:event="startDrag"
          @touchstart:event="show"
          @touchstart:time="startTime"
          @touchmove:time="mouseMove"
          @touchend:time="endDrag"
          @mouseleave.native="cancelDrag"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :close-on-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon>
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Fermer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
      <v-sheet v-else height="calc(100% - 64px)">
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :interval-height="48"
          :event-height="25"
          color="primary"
          :locale="locale"
          :type="types[toggle_type]"
          :interval-style="styleInterval"
          :event-name="eventName"
          :events="allEvents"
          :event-color="getEventColor"
          :short-weekdays="false"
          @change="updateRange"
          :first-interval="7"
          :interval-count="15"
          :interval-format="intervalFormat"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewWeek"
          @mousedown:event="startDrag"
          @mouseup:event="show"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
        ></v-calendar>
      </v-sheet>
      <v-row class="pa-0">
        <v-col cols="1" class="pt-6">
          <v-btn @click="reLoad" color="primary" title="Actualiser">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="selected"
            label="Afficher les réservations"
            value="resa"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="selected"
            label="Afficher mes interventions"
            value="myInter"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="selected"
            label="Afficher toutes les interventions"
            value="allInter"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-col>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <ReservationItemMenu
        :selectedEvent="selectedEvent"
        :reservations="reservations"
        :progress="round(progressValue)"
        :loadingText="loadingText"
        :dragData="dragData"
        :overlayStatus="overlay"
        @deleteReservation="deleteReservation"
        @updateReservation="updateReservation"
        @updateRecursiveReservation="updateRecursiveReservations"
        @closeSelected="close"
      ></ReservationItemMenu>
    </v-menu>
    <v-dialog
      ref="crudDialog"
      v-model="addEventDialog"
      persistent
      max-width="600px"
    >
      <add-reservation-form
        v-if="!updateDialog"
        :reservationItems="reservationItems"
        :eventLoading="eventLoading"
        :event="event"
        :overlay="overlay"
        :progress="round(progressValue)"
        @canceled="closeEventDialog"
        @addReservation="addReservation"
        @addRecursiveReservation="addRecursiveReservations"
      ></add-reservation-form>
    </v-dialog>
    <v-overlay :value="overlay">
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          indeterminate
          size="64"
          v-if="progressValue == 0"
        ></v-progress-circular>
        <v-progress-circular
          v-else
          :rotate="360"
          :size="100"
          :width="15"
          indeterminate
          color="error"
        >
          <span class="white--text">
            <strong>{{ round(progressValue) }} %</strong>
          </span>
        </v-progress-circular>
        <span class="mt-5"> {{ loadingText || "Loading" }}... </span>
      </div>
    </v-overlay>
  </v-row>
</template>

<script>
import TICKET_API from "../api/ticket";
import moment from "../plugins/moment";
import { capitalize, shouldDisplay, uniqueElementBy } from "../utils";
import { mapGetters } from "vuex";
import { convert } from "../utils/icsToJson";
import { EventBus } from "../event-bus";
import { PORTAL_CONFIG } from "../config";
import AddReservationForm from "./calendar/AddReservation.vue";
import ReservationItemMenu from "./calendar/ReservationItemMenu.vue";
import {
  CalendarDragableMixin,
  BaseCalendarMixin,
  CalendarCrudMixin
} from "../mixins/calendar";
export default {
  name: "Calendar",
  components: {
    AddReservationForm,
    ReservationItemMenu
  },
  mixins: [CalendarDragableMixin, BaseCalendarMixin, CalendarCrudMixin],
  data: () => ({
    reservations: [],
    reservationItems: [],
    updateDialog: false,
    overlay: false,
    events: [],
    events2: [],
    icsEvents: [],
    icsCalendarUid: [],
    eqpt: "",
    selected: ["resa", "myInter"],
    time: ["days", "weeks", "months"],
    repeatEventItems: []
  }),
  methods: {
    reLoad() {
      this.loadData();
      this.loadCalendar();
    },
    loadData() {
      if (this.selected.includes("resa")) {
        this.reservations = [];
        this.reservationItems = [];
        this.events = [];
        TICKET_API.getReservationItems().then(({ data }) => {
          const totalColors = this.colors.length;
          let reservationItems = data;
          TICKET_API.getReservations().then(({ data }) => {
            reservationItems.forEach(async (item, i) => {
              let reservations = data.filter(
                el => el.reservationitems_id === item.id
              );
              let response = await TICKET_API.getReservationItem(
                item.itemtype,
                item.items_id
              );

              let reservationItem = response.data;
              let name = reservationItem.name;

              reservationItem.itemtype = item.itemtype;
              reservationItem.items_id = item.items_id;
              reservationItem.reservationitems_id = item.id;
              this.reservationItems.push(reservationItem);

              reservations.forEach(res => {
                if (shouldDisplay(res)) {
                  this.events.push({
                    name: `${name} -
                    ${
                      JSON.parse(res.comment).guest
                        ? JSON.parse(res.comment).guest
                        : JSON.parse(res.comment).email
                    }`,
                    start: moment(res.begin).format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(res.end).format("YYYY-MM-DD HH:mm:ss"),
                    color:
                      JSON.parse(res.comment).state == 0
                        ? "rgb(155, 155, 155)"
                        : this.colors[i % totalColors],
                    comment: JSON.parse(res.comment).comment,
                    tel: JSON.parse(res.comment).tel,
                    email: JSON.parse(res.comment).email,
                    guest: JSON.parse(res.comment).guest,
                    state: JSON.parse(res.comment).state,
                    id: res.id
                  });
                }
              });
              this.$refs.calendar.checkChange();
              this.reservations = this.reservations.concat(reservations);
            });
          });
        });
      } else {
        this.reservations = [];
        this.reservationItems = [];
        this.events = [];
      }
    },
    loadCalendar() {
      if (
        this.selected.includes("allInter") ||
        this.selected.includes("myInter")
      ) {
        this.icsEvents = [];
        this.icsCalendarUid = [];
        if (this.calendarUrl.length == 2) {
          this.calendarUrl.forEach(url => {
            fetch(url, {
              headers: {
                "Content-Type": "text/calendar; charset=utf-8"
              }
            })
              .then(response => {
                return response.text();
              })
              .then(data => {
                let icsCalendar = convert(data);
                if (icsCalendar && icsCalendar.VCALENDAR) {
                  const calendar = icsCalendar.VCALENDAR[0];
                  const totalColors = this.icsColors.length;
                  calendar.VEVENT &&
                    calendar.VEVENT.forEach(res => {
                      if (!this.icsCalendarUid.includes(res.UID))
                        this.icsCalendarUid.push(res.UID);
                      this.icsEvents.push({
                        name: res.DESCRIPTION.split("/")[0],
                        start: moment(res.DTSTART).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ),
                        end: moment(res.DTEND).format("YYYY-MM-DD HH:mm:ss"),
                        color: this.icsColors[
                          this.getIcsColor(res.UID) % totalColors
                        ],
                        comment: res.SUMMARY || ""
                      });
                    });
                }
              });
          });
        } else {
          fetch(this.calendarUrl, {
            headers: {
              "Content-Type": "text/calendar; charset=utf-8"
            }
          })
            .then(response => {
              return response.text();
            })
            .then(data => {
              let icsCalendar = convert(data);

              if (icsCalendar && icsCalendar.VCALENDAR) {
                const calendar = icsCalendar.VCALENDAR[0];
                const totalColors = this.icsColors.length;
                calendar.VEVENT &&
                  calendar.VEVENT.forEach(res => {
                    if (!this.icsCalendarUid.includes(res.UID))
                      this.icsCalendarUid.push(res.UID);
                    this.icsEvents.push({
                      name: res.DESCRIPTION.split("/")[0],
                      start: moment(res.DTSTART).format("YYYY-MM-DD HH:mm:ss"),
                      end: moment(res.DTEND).format("YYYY-MM-DD HH:mm:ss"),
                      color: this.icsColors[
                        this.getIcsColor(res.UID) % totalColors
                      ],
                      comment: res.SUMMARY || ""
                    });
                  });
              }
            });
        }
      } else {
        this.icsEvents = [];
        this.icsCalendarUid = [];
      }
    },
    getIcsColor(Uid) {
      return this.icsCalendarUid.findIndex(c => c === Uid);
    },
    resetDialogData() {
      this.dialog = false;
      this.overlay = false;
      this.addEventDialog = false;
      this.progressValue = 0;
      this.loadingText = undefined;
    },
    init() {
      if (this.getUserProfileStatus && this.isActiveEntityLoaded) {
        this.loadData();
      }
    }
  },
  mounted() {
    this.init();

    if (this.getPersonalToken) {
      this.loadCalendar();
    }

    EventBus.$on("calendar", data => {
      if (data.direction === "next") {
        this.next();
      } else {
        this.prev();
      }
    });
  },
  computed: {
    ...mapGetters([
      "isActiveEntityLoaded",
      "getUserId",
      "getGLPGroup",
      "getPersonalToken",
      "getSessionToken",
      "getUUID",
      "getUserProfileStatus"
    ]),
    locale() {
      return this.$root.$i18n.locale;
    },
    calendarUrl() {
      //return `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`;
      if (!this.selected.includes("allInter")) {
        return `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`;
      } else {
        return [
          `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=${this.getUserId}&gID=0&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`,
          `${PORTAL_CONFIG.FRONT_URL}/planning.php/?genical=1&uID=0&gID=${this.getGLPGroup}&entities_id=${this.$route.params.id}&is_recursive=0&token=${this.getPersonalToken}`
        ];
      }
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const date = moment(start.date)
        .locale(this.locale)
        .format("MMMM YYYY");
      const week = moment(start.date)
        .locale(this.locale)
        .week();

      let title = `<h2>${capitalize(date)}</h2>`;

      return this.toggle_type === 2
        ? title
        : `${title}/ <span style="color: red">Semaine ${week}</span>`;
    },
    smalltitle() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const date = moment(start.date)
        .locale(this.locale)
        .format("MM/YY");
      const week = moment(start.date)
        .locale(this.locale)
        .week();

      let smalltitle = `<h4>${capitalize(date)}</h4>`;

      return this.toggle_type === 2
        ? smalltitle
        : `${smalltitle}<h5 style="color: white">-</h5><h5 style="color: red">S${week}</h5>`;
    },
    allEvents() {
      return this.uniqEvents.concat(this.events2).map(event => {
        let start = moment(event.start).format("DD MMMM YYYY à HH:mm:ss");
        let end = moment(event.end).format("DD MMMM YYYY à HH:mm:ss");
        const details = `<table class="event--table"><tr><td>Debut:</td> <td>${start}</td></tr><tr><td>Fin:</td><td>${end}</td></tr></table>`;
        event.details = details;
        event.timed = true;
        return event;
      });
    },
    uniqEvents() {
      return uniqueElementBy(
        this.events.concat(this.icsEvents),
        (a, b) => a.start == b.start && a.name == b.name
      );
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    isMobilePaysage() {
      return (
        this.$vuetify.breakpoint.width <
          this.$vuetify.breakpoint.thresholds.lg &&
        this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width
      );
    }
  },
  watch: {
    getUserProfileStatus() {
      this.init();
    },
    getPersonalToken() {
      if (this.getPersonalToken) {
        this.loadCalendar();
      }
    },
    getGLPGroup() {
      this.$nextTick(() => {
        if (this.getUserProfileStatus) {
          this.loadData();
        }

        if (this.getPersonalToken) {
          this.loadCalendar();
        }
      });
    },
    isActiveEntityLoaded() {
      this.init();
    }
  }
};
</script>

<style>
.pl-1 {
  font-size: 0.9rem;
}
#calendar-view {
  position: absolute;
  top: 0;
  min-height: 100vh;
  width: 100%;
}
#calendar-view #toolbar header {
  background-color: transparent;
  position: relative;
  z-index: 3;
}
.v-calendar-weekly__head {
  height: 50px;
  padding-top: 5px;
  background-color: #f7f7f7;
}
.v-calendar-weekly__head-weekday {
  display: flex;
  align-items: center;
  justify-content: center;
}
.event--table th,
.event--table td {
  padding: 15px;
  text-align: left;
}
</style>
