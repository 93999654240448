<template>
  <ticket-access filtered>
    <template v-slot="slotProps">
      <v-row align="start" justify="center" class="fill-height mx-0">
        <v-col md="12" class="pt-5">
          <condition-editor
            :comment="note"
            :title="$t('tableStatus.access_instructions')"
          >
          </condition-editor>
        </v-col>
        <v-col cols="12">
          <tickets-chartAccess
            :tickets="slotProps.exposedData.tickets"
            :loaded="slotProps.exposedData.loaded"
          ></tickets-chartAccess>
        </v-col>
        <v-col cols="12" class="px-0 px-md-0">
          <access
            :loaded="slotProps.exposedData.loaded"
            :loading="slotProps.exposedData.loading"
            :tickets="slotProps.exposedData.tickets"
          ></access>
        </v-col>
      </v-row>
    </template>
  </ticket-access>
</template>
<script>
import Access from "./Access";
import TicketsChartAccess from "./TicketsChartAccess";
import TicketAccess from "./TicketAccess";
import ConditionEditor from "../editor/ConditionEditor";
import USER_API from "../../api/user";

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  name: "OngletAccess",
  components: { TicketsChartAccess, Access, TicketAccess, ConditionEditor },
  data() {
    return {
      note: null,
      comment: ""
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      if (this.active) {
        USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
          const size = data.length;
          if (size > 0) {
            for (let i = 0; i < size; i++) {
              if (data[i].content.substring(0, 5) === "BBBBB") {
                this.note = data[i].content.substring(5);
                break;
              } else {
                this.note = "Pas de note enregistrée";
              }
            }
          } else {
            this.note = null;
          }
        });
      }
    }
  },
  watch: {
    $route() {
      this.loadData();
    },
    active() {
      this.loadData();
    }
  }
};
</script>
