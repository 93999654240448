<template>
  <v-card style="position: relative; height: 650px">
    <v-card class="elevation-0">
      <v-card-title class="headline display-1">
        {{ $t("labels.access_review") }}
      </v-card-title>
      <v-card-text>
        <bar-chart
          :chart-data="collection"
          :height="500"
          :width="null"
        ></bar-chart>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import _groupby from "lodash.groupby";
import { DefaultChartProps } from "../../mixins/default-chart-props";
import BarChart from "./BarChart.js";
export default {
  name: "DefaultChart",
  components: { BarChart },
  props: {
    statusList: {
      type: Array,
      required: true
    },
    searchKey: {
      type: String,
      default: "name"
    }
  },
  mixins: [DefaultChartProps],
  computed: {
    collection() {
      let data = {};
      const filteredTickets = this.tickets.filter(
        x => x[this.searchKey] !== undefined
      );
      data.labels = Object.keys(_groupby(filteredTickets, this.searchKey));
      data.datasets = [];
      this.statusList.forEach(status => {
        data.datasets.push({
          label: status.name,
          data: data.labels.map(ticket => {
            return filteredTickets.filter(
              tkt =>
                tkt[this.searchKey] === ticket && tkt.status === status.status
            ).length;
          }),
          backgroundColor: status.color
        });
      });
      return data;
    }
  }
};
</script>
<style scoped>
.chart_ {
  height: 400px !important;
}
</style>
